// import * as React from 'react';
// import Box from '@mui/material/Box';

// export default function LabTabs() {
//   const [value, setValue] = React.useState('1');

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <div className='tabs'>
//         <button className='tabs-btn'>Monthly</button>
//         <button className='tabs-btn'>Annually</button>
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import PricingComponent from "../UI/PricingComponent";
import SubscriptionCards from "./subscriptionCards";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";

const Tab = ({ label, isActive, onClick, percentOff }) => {
  const activeTabStyle = {
    // borderBottom: "2px solid blue",
    paddingBottom: "5px",
    fontWeight: "bold",
    // borderBottom: "2px solid",
    // borderImageSource: "linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%) 1",
    // borderImage: "linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%) 1",
    // borderWidth: "2px",
    // borderStyle: "solid",
    background: "linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
  };
  const inactiveTabStyle = {
    borderBottom: "none",
    fontWeight: "bold",
  };

  const activeUnderline = {
    width: "100%",
    height: "5px",
    background: "linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%)",
    borderRadius: "10px",
  };

  return (
    <>
      <div className="btn-underline">
        <div className="tabs-btn-parent tabs-btn">
          <div
            onClick={onClick}
            style={isActive ? activeTabStyle : inactiveTabStyle}
            className={percentOff?.length ? "pr-2" : ""}
          >
            {label}
          </div>
          {percentOff?.length && (
            <div className="percent-off-parent">{percentOff}</div>
          )}
        </div>
        <div style={isActive ? activeUnderline : inactiveTabStyle}></div>
      </div>
    </>
  );
};

const BasicTabs = ({
  picingData,
  handleChange,
  userData,
  packages,
  selectCurrency,
  setSelectCurrency,
  currencyDropdownDisabled,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [packagesData, setPackagesData] = useState([]);

  let navigation = useNavigate();

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  useEffect(() => {
    if (packages?.length) {
      if (activeTab === 1) {
        let filter = packages?.filter(
          (ev) => ev?.recurring?.interval === "year"
        );
        let sort = filter.sort(
          (a, b) => a.unit_amount / 100 - b.unit_amount / 100
        );
        console.log(sort);
        setPackagesData(sort);
      } else {
        let filter = packages?.filter(
          (ev) => ev?.recurring?.interval === "month"
        );
        let sort = filter.sort(
          (a, b) => a.unit_amount / 100 - b.unit_amount / 100
        );
        console.log(sort);
        setPackagesData(sort);
      }
    }
  }, [activeTab, packages]);
  useEffect(() => {
    console.log(userData);
  }, [userData]);
  return (
    <div>
      <div className="tabs">
        <div className="capsule">
          <Tab
            label="Monthly"
            isActive={activeTab === 0}
            onClick={() => handleTabClick(0)}
          />
          <Tab
            label="Annually"
            percentOff="-20%"
            isActive={activeTab === 1}
            onClick={() => handleTabClick(1)}
          />
        </div>
        {/* <select
          className="select-currency"
          onChange={(ev) => setSelectCurrency(ev?.target.value)}
          >
          <option value={"EUR"}>EUR</option>
          <option value={"USD"}>USD</option>
          <option value={"GBP"}>GBP</option>
        </select> */}
      </div>
      <div className="select-country-parent">
        <h6>Select currency:</h6>
        <Select
          defaultValue="EUR"
          disabled={currencyDropdownDisabled}
          value={selectCurrency}
          style={{ width: 120 }}
          onChange={(ev) => setSelectCurrency(ev)}
          options={[
            { value: "USD", label: "USD" },
            { value: "EUR", label: "EUR" },
            { value: "GBP", label: "GBP" },
          ]}
        />
      </div>
      <div className="SubscriptionPlan pricing-component">
        <div className="SubscriptionCards-Parent">
          <SubscriptionCards
            index={0}
            stripeId={process.env.REACT_APP_STRIPE_PRODUCT_0_ID}
            select={true}
            handleChange={handleChange}
            picingData={picingData}
            plansText={"Free"}
            price={0}
            selectPlan={"Select Plan"}
            userData={userData}
            paragraphText={
              activeTab === 1 ? "Billed Annually." : "Billed Monthly."
            }
            // url={"/Assets/Images/puzzle.png"}
            headertext={"Free"}
            navigation={navigation}
            headerpara={``}
            subscriptionPlans={[
              `250 credits`,
              "Instagram search",
              "Instagram URL imports feature",
              "Spotify artists search",
              "Spotify playlists search",
              "SoundCloud search",
              "TikTok search",
              "Youtube search",
              "Twitter search",
              "Export of results",
              "Priority support ",
              "Direct message",
            ]}
            disabled={
              userData?.user_metadata?.freeVersion == "claimed" ? true : false
            }
            confirmPayment={true}
            id={"card1"}
          />
          {packagesData?.map((ev, i) => {
            let prices =
              selectCurrency === "USD"
                ? ev?.currency_options?.usd?.unit_amount / 100
                : selectCurrency === "GBP"
                ? ev?.currency_options?.gbp?.unit_amount / 100
                : ev?.currency_options?.eur?.unit_amount / 100;
            return (
              <SubscriptionCards
                index={i + 1}
                stripeId={ev?.id}
                select={true}
                handleChange={handleChange}
                picingData={picingData}
                plansText={
                  ev?.recurring?.interval === "year"
                    ? ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_Yearly)
                      ? "Starter"
                      : ev?.unit_amount / 100 ===
                        Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_Yearly)
                      ? "Medium"
                      : ev?.unit_amount / 100 ===
                        Number(process.env.REACT_APP_STRIPE_PRICE_PRO_Yearly)
                      ? "Pro"
                      : ""
                    : ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_MONTHLY)
                    ? "Starter"
                    : ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY)
                    ? "Medium"
                    : ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_PRO_MONTHLY)
                    ? "Pro"
                    : ""
                }
                price={prices}
                headertext={`${
                  selectCurrency === "USD"
                    ? "$"
                    : selectCurrency === "GBP"
                    ? "£"
                    : "€"
                }${
                  ev?.recurring?.interval === "year"
                    ? Math.floor(Number(prices / 12))
                    : Math.floor(prices)
                }`}
                selectPlan={"Select Plan"}
                userData={userData}
                navigation={navigation}
                // url={"/Assets/Images/premium-product.png"}
                // headertext={`€${pro}`}
                paragraphText={
                  ev?.recurring?.interval === "year"
                    ? "Billed Annually."
                    : "Billed Monthly."
                }
                headerpara={`/ Month`}
                SubscriptionText={"Buy Now"}
                subscriptionPlans={
                  ev?.recurring?.interval === "year"
                    ? ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_Yearly)
                      ? [
                          `${process.env.REACT_APP_STRIPE_CREDITS_STARTER_Yearly} credits per ${ev?.recurring?.interval}`,
                          "Instagram search",
                          "Instagram URL imports feature",
                          "Spotify artists search",
                          "Spotify playlists search",
                          "SoundCloud search",
                          "TikTok search",
                          "Youtube search",
                          "Twitter search",
                          "Export of results",
                          "Priority support ",
                          "Direct message",
                        ]
                      : ev?.unit_amount / 100 ===
                        Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_Yearly)
                      ? [
                          `${process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly} credits per ${ev?.recurring?.interval}`,
                          "Instagram search",
                          "Instagram URL imports feature",
                          "Spotify artists search",
                          "Spotify playlists search",
                          "SoundCloud search",
                          "TikTok search",
                          "Youtube search",
                          "Twitter search",
                          "Export of results",
                          "Priority support ",
                          "Direct message",
                        ]
                      : ev?.unit_amount / 100 ===
                        Number(process.env.REACT_APP_STRIPE_PRICE_PRO_Yearly)
                      ? [
                          `${process.env.REACT_APP_STRIPE_CREDITS_PRO_Yearly} credits per ${ev?.recurring?.interval}`,
                          "Instagram search",
                          "Instagram URL imports feature",
                          "Spotify artists search",
                          "Spotify playlists search",
                          "SoundCloud search",
                          "TikTok search",
                          "Youtube search",
                          "Twitter search",
                          "Export of results",
                          "Priority support",
                          "Direct message",
                        ]
                      : []
                    : ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_MONTHLY)
                    ? [
                        `${process.env.REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY} credits per ${ev?.recurring?.interval}`,
                        "Instagram search",
                        "Instagram URL imports feature",
                        "Spotify artists search",
                        "Spotify playlists search",
                        "SoundCloud search",
                        "TikTok search",
                        "Youtube search",
                        "Twitter search",
                        "Export of results",
                        "Priority support ",
                        "Direct message",
                      ]
                    : ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY)
                    ? [
                        `${process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY} credits per ${ev?.recurring?.interval}`,
                        "Instagram search",
                        "Instagram URL imports feature",
                        "Spotify artists search",
                        "Spotify playlists search",
                        "SoundCloud search",
                        "TikTok search",
                        "Youtube search",
                        "Twitter search",
                        "Export of results",
                        "Priority support ",
                        "Direct message",
                      ]
                    : ev?.unit_amount / 100 ===
                      Number(process.env.REACT_APP_STRIPE_PRICE_PRO_MONTHLY)
                    ? [
                        `${process.env.REACT_APP_STRIPE_CREDITS_PRO_MONTHLY} credits per ${ev?.recurring?.interval}`,
                        "Instagram search",
                        "Instagram URL imports feature",
                        "Spotify artists search",
                        "Spotify playlists search",
                        "SoundCloud search",
                        "TikTok search",
                        "Youtube search",
                        "Twitter search",
                        "Export of results",
                        "Priority support",
                        "Direct message",
                      ]
                    : []
                }
                confirmPayment={true}
                id={`card${i + 2}`}
              />
            );
          })}
        </div>
      </div>
      {/* Render the content based on the active tab */}
      {/* {activeTab === 0 && (
        <PricingComponent
          para="month"
          starter="25"
          medium="100"
          pro="200"
          picingData={picingData}
          userData={userData}
          handleChange={handleChange}
          select={true}
          text="Select Plan"
        />
      )}
      {activeTab === 1 && (
        <PricingComponent
          para="year"
          userData={userData}
          starter="100"
          medium="200"
          pro="400"
          picingData={picingData}
          handleChange={handleChange}
          select={true}
          text="Select Plan"
        />
      )} */}
    </div>
  );
};

export default BasicTabs;
