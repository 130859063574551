import React from "react";
import DeepSearch from "./filter/deepSearch";
import AccountRange from "./filter/accountRange";
import AccountShould from "./filter/accountShould";
import AccountFollower from "./filter/accountFollower";

function FilterCards({
  onClose,
  isToggled,
  setIsToggled,
  platform,
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  return (
    <div className="filter-menu-item">
      {<DeepSearch platform={platform} />}

      <AccountFollower
        isToggled={isToggled}
        setIsToggled={setIsToggled}
        platform={platform}
        disabledButton={disabledButton}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
      />
      {/* <AccountRange />
      <AccountShould /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 15,
          marginTop: 40,
        }}
      >
        <button
          onClick={onClose}
          className="flaot-import-button flaot-border-radius float-button  download-btn-width  cancel"
          style={{
            fontSize: 14,
            border: 2,
            color: "#5C6C84",
            background: "white",
          }}
        >
          Cancel
        </button>
        <button
          onClick={onClose}
          className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width apply"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
}

export default FilterCards;
