import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import InprogressComponent from "../Components/inprogressComponent";
import jwt_decode from "jwt-decode";
import FooterComponent from "../Components/footerComponent";
import axios from "axios";
import HorizontalStepper from "../Components/HorizontalStepper";
import HeaderUI from "./HeaderUI";
import { toast } from "react-toastify";
function StripePaymentauth({
  userData,
  setApiCall2,
  setApiCall,
  apiCall,
  setUserData,
  apiToken,
  isAuthenticated,
  isLoading,
}) {
  const stripe = require("stripe")(process.env.REACT_APP_STRIPE_CLIENT_SECRET);
  const [loader, setLoader] = useState(false);
  const navigation = useNavigate();
  var url = new URL(window.location);
  let subscriptionPlan = url.searchParams.get("planID");
  let select = url.searchParams.get("select");
  let redirect = url.searchParams.get("redirect");
  let open = url.searchParams.get("open");
  useEffect(() => {
    console.log(redirect);
    if (!isLoading && !isAuthenticated) {
      if (!localStorage.getItem("payment-intent")) {
        if (select === "free") {
        } else {
          navigation("/home");
        }
      }
    }
  }, [userData, isAuthenticated, isLoading]);
  useEffect(() => {
    if (userData?.user_id) {
      if (apiToken !== "") {
        if (userData && localStorage.getItem("payment-intent")) {
          stripe.subscriptions
            .list({
              customer: userData?.app_metadata?.stripe_customer_id,
            })
            .then((subscriptions) => {
              let data;
              data = subscriptions.data[0];
              console.log(data);
              stripe.checkout.sessions
                .retrieve(localStorage.getItem("payment-intent"))
                .then((response) => {
                  console.log(response, "response");
                  if (response?.status === "complete") {
                    // if (response?.amount_subtotal === 0) {
                    //   let user_metadata = userData?.user_metadata?.subscriptionPlan
                    //     ? {
                    //         subscriptionPlan: "Free",
                    //         remainingCredits:
                    //           250 + userData?.user_metadata?.remainingCredits,
                    //         credits: 250 + userData?.user_metadata?.credits,
                    //         exp_date: data?.current_period_start,
                    //         freeVersion: "claimed",
                    //       }
                    //     : {
                    //         subscriptionPlan: "Free",
                    //         remainingCredits: 250,
                    //         credits: 250,
                    //         exp_date: data?.current_period_start,
                    //         freeVersion: "claimed",
                    //       };
                    //   // var params = { id: JSON.parse(localStorage.getItem("auth-Id")) };
                    //   var options = {
                    //     method: "post",
                    //     url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
                    //     headers: { "content-type": "application/json" },
                    //     data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
                    //   };
                    //   axios(options)
                    //     .then((res) => {
                    //       var options = {
                    //         method: "PATCH",
                    //         url: `${
                    //           process.env.REACT_APP_AUTH0_API_DOMAIN
                    //         }/api/v2/users/${JSON.parse(
                    //           localStorage.getItem("auth-Id")
                    //         )}`,
                    //         headers: {
                    //           authorization: `Bearer ${res.data?.access_token}`,
                    //           "content-type": "application/json",
                    //         },
                    //         data: {
                    //           user_metadata: {
                    //             token: res.data?.access_token,
                    //             ...user_metadata,
                    //           },
                    //         },
                    //       };
                    //       axios
                    //         .request(options)
                    //         .then(function (response) {
                    //           setUserData(response.data);
                    //           setApiCall2(true);
                    //           localStorage.removeItem("payment-intent");
                    //           navigation(
                    //             redirect ? `/${redirect}?open=${open}` : "/home"
                    //           );
                    //         })
                    //         .catch(function (error) {
                    //           console.error(error);
                    //         });
                    //     })
                    //     .catch(function (error) {
                    //       console.error(error);
                    //     });
                    // } else {
                    stripe.prices
                      .retrieve(response?.metadata?.productId)
                      .then((res) => {
                        console.log(res);
                        console.log(res?.unit_amount / 100, "res unit_amount");
                        console.log(
                          res?.plan?.interval,
                          "year",
                          "res unit_amount bvgh"
                        );
                        let user_metadata =
                          res?.plan?.interval === "year" ||
                          res?.recurring?.interval === "year"
                            ? userData?.user_metadata?.subscriptionPlan
                              ? {
                                  subscriptionPlan:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? "Starter"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? "Medium"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? "Pro"
                                      : null,
                                  remainingCredits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        ) +
                                        userData?.user_metadata
                                          ?.remainingCredits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        ) +
                                        userData?.user_metadata
                                          ?.remainingCredits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        ) +
                                        userData?.user_metadata
                                          ?.remainingCredits
                                      : null,
                                  credits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        ) + userData?.user_metadata?.credits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        ) + userData?.user_metadata?.credits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        ) + userData?.user_metadata?.credits
                                      : null,
                                  exp_date: data?.current_period_start,
                                }
                              : {
                                  subscriptionPlan:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ==
                                    res?.unit_amount / 100
                                      ? "Starter"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ==
                                        res?.unit_amount / 100
                                      ? "Medium"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ==
                                        res?.unit_amount / 100
                                      ? "Pro"
                                      : null,
                                  remainingCredits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ==
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ==
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ==
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        )
                                      : null,
                                  credits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ==
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ==
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ==
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        )
                                      : null,
                                  exp_date: data?.current_period_start,
                                }
                            : userData?.user_metadata?.subscriptionPlan
                            ? {
                                subscriptionPlan:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? "Starter"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Medium"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Pro"
                                    : null,
                                remainingCredits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      ) +
                                      userData?.user_metadata?.remainingCredits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      ) +
                                      userData?.user_metadata?.remainingCredits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      ) +
                                      userData?.user_metadata?.remainingCredits
                                    : null,
                                credits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      ) + userData?.user_metadata?.credits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      ) + userData?.user_metadata?.credits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      ) + userData?.user_metadata?.credits
                                    : null,
                                exp_date: data?.current_period_start,
                              }
                            : {
                                subscriptionPlan:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? "Starter"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Medium"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Pro"
                                    : null,
                                remainingCredits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      )
                                    : null,
                                credits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      )
                                    : null,
                                exp_date: data?.current_period_start,
                              };
                        console.log(user_metadata, "user_metadata");
                        var options = {
                          method: "PATCH",
                          url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
                          headers: {
                            authorization: `Bearer ${apiToken}`,
                            "content-type": "application/json",
                          },
                          data: {
                            user_metadata: {
                              ...user_metadata,
                              token: apiToken,
                            },
                          },
                        };
                        axios
                          .request(options)
                          .then(function (response) {
                            setUserData(response.data);
                            localStorage.removeItem("payment-intent");
                            if (open === "message") {
                              if (
                                response.data?.user_metadata
                                  ?.subscriptionPlan === "Pro"
                              ) {
                                navigation(
                                  redirect
                                    ? `/${redirect}?open=${open}`
                                    : "/home"
                                );
                              } else {
                                navigation(redirect ? `/${redirect}` : "/home");
                              }
                            } else if (open === "PDF" || open === "CSV") {
                              if (
                                response.data?.user_metadata
                                  ?.subscriptionPlan !== "Free"
                              ) {
                                navigation(
                                  redirect
                                    ? `/${redirect}?open=${open}`
                                    : "/home"
                                );
                              } else {
                                navigation(redirect ? `/${redirect}` : "/home");
                              }
                            } else {
                              navigation(redirect ? `/${redirect}` : "/home");
                            }
                            setApiCall2(true);
                          })
                          .catch(function (error) {
                            console.error(
                              error?.response?.data?.error === "Unauthorized"
                            );
                            console.error(error);
                            console.error(error?.data);
                            console.error(error?.error);
                            if (
                              error?.response?.data?.error === "Unauthorized"
                            ) {
                              var options = {
                                method: "post",
                                url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
                                headers: { "content-type": "application/json" },
                                data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
                              };
                              axios(options)
                                .then((res) => {
                                  var options = {
                                    method: "PATCH",
                                    url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
                                    headers: {
                                      authorization: `Bearer ${res.data?.access_token}`,
                                      "content-type": "application/json",
                                    },
                                    data: {
                                      user_metadata: {
                                        ...user_metadata,
                                        token: res.data?.access_token,
                                      },
                                    },
                                  };
                                  axios
                                    .request(options)
                                    .then(function (response) {
                                      // console.log(response.data, "response.setUserData");
                                      setUserData(response.data);
                                      localStorage.removeItem("payment-intent");
                                      if (open === "message") {
                                        if (
                                          response.data?.user_metadata
                                            ?.subscriptionPlan === "Pro"
                                        ) {
                                          navigation(
                                            redirect
                                              ? `/${redirect}?open=${open}`
                                              : "/home"
                                          );
                                        } else {
                                          navigation(
                                            redirect ? `/${redirect}` : "/home"
                                          );
                                        }
                                      } else if (
                                        open === "PDF" ||
                                        open === "CSV"
                                      ) {
                                        if (
                                          response.data?.user_metadata
                                            ?.subscriptionPlan !== "Free"
                                        ) {
                                          navigation(
                                            redirect
                                              ? `/${redirect}?open=${open}`
                                              : "/home"
                                          );
                                        } else {
                                          navigation(
                                            redirect ? `/${redirect}` : "/home"
                                          );
                                        }
                                      } else {
                                        navigation(
                                          redirect ? `/${redirect}` : "/home"
                                        );
                                      }
                                      setApiCall2(true);
                                      // setApiCall(!apiCall);
                                      // setLoader(false);
                                    })
                                    .catch(function (error) {
                                      console.error(error);
                                    });
                                })
                                .catch(function (error) {
                                  console.error(error);
                                });
                            }
                          });
                        // if (userData?.user_metadata?.token) {
                        //   var options = {
                        //     method: "PATCH",
                        //     url: `${
                        //       process.env.REACT_APP_AUTH0_API_DOMAIN
                        //     }/api/v2/users/${JSON.parse(
                        //       localStorage.getItem("auth-Id")
                        //     )}`,
                        //     headers: {
                        //       authorization: `Bearer ${userData?.user_metadata?.token}`,
                        //       "content-type": "application/json",
                        //     },
                        //     data: {
                        //       user_metadata: {
                        //         ...user_metadata,
                        //         token: userData?.user_metadata?.token,
                        //       },
                        //     },
                        //   };
                        //   axios
                        //     .request(options)
                        //     .then(function (response) {
                        //       // console.log(response.data, "response.setUserData");
                        //       setUserData(response.data);
                        //       localStorage.removeItem("payment-intent");
                        //       navigation(
                        //         redirect ? `/${redirect}?open=${open}` : "/home"
                        //       );
                        //       setApiCall2(true);
                        //       // setApiCall(!apiCall);
                        //       // setLoader(false);
                        //     })
                        //     .catch(function (error) {
                        //       console.error(
                        //         error?.response?.data?.error === "Unauthorized"
                        //       );
                        //       console.error(error);
                        //       console.error(error?.data);
                        //       console.error(error?.error);
                        //       if (error?.response?.data?.error === "Unauthorized") {
                        //         var options = {
                        //           method: "post",
                        //           url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
                        //           headers: { "content-type": "application/json" },
                        //           data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
                        //         };
                        //         axios(options)
                        //           .then((res) => {
                        //             var options = {
                        //               method: "PATCH",
                        //               url: `${
                        //                 process.env.REACT_APP_AUTH0_API_DOMAIN
                        //               }/api/v2/users/${JSON.parse(
                        //                 localStorage.getItem("auth-Id")
                        //               )}`,
                        //               headers: {
                        //                 authorization: `Bearer ${res.data?.access_token}`,
                        //                 "content-type": "application/json",
                        //               },
                        //               data: {
                        //                 user_metadata: {
                        //                   ...user_metadata,
                        //                   token: res.data?.access_token,
                        //                 },
                        //               },
                        //             };
                        //             axios
                        //               .request(options)
                        //               .then(function (response) {
                        //                 // console.log(response.data, "response.setUserData");
                        //                 setUserData(response.data);
                        //                 localStorage.removeItem("payment-intent");
                        //                 navigation(
                        //                   redirect
                        //                     ? `/${redirect}?open=${open}`
                        //                     : "/home"
                        //                 );
                        //                 setApiCall2(true);
                        //                 // setApiCall(!apiCall);
                        //                 // setLoader(false);
                        //               })
                        //               .catch(function (error) {
                        //                 console.error(error);
                        //               });
                        //           })
                        //           .catch(function (error) {
                        //             console.error(error);
                        //           });
                        //       }
                        //     });
                        // }
                        // else {
                        //   var options = {
                        //     method: "post",
                        //     url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
                        //     headers: { "content-type": "application/json" },
                        //     data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
                        //   };
                        //   axios(options)
                        //     .then((res) => {
                        //       var options = {
                        //         method: "PATCH",
                        //         url: `${
                        //           process.env.REACT_APP_AUTH0_API_DOMAIN
                        //         }/api/v2/users/${JSON.parse(
                        //           localStorage.getItem("auth-Id")
                        //         )}`,
                        //         headers: {
                        //           authorization: `Bearer ${res.data?.access_token}`,
                        //           "content-type": "application/json",
                        //         },
                        //         data: {
                        //           user_metadata: {
                        //             ...user_metadata,
                        //             token: res.data?.access_token,
                        //           },
                        //         },
                        //       };
                        //       axios
                        //         .request(options)
                        //         .then(function (response) {
                        //           // console.log(response.data, "response.setUserData");
                        //           setUserData(response.data);
                        //           localStorage.removeItem("payment-intent");
                        //           navigation(
                        //             redirect ? `/${redirect}?open=${open}` : "/home"
                        //           );
                        //           setApiCall2(true);
                        //           // setApiCall(!apiCall);
                        //           // setLoader(false);
                        //         })
                        //         .catch(function (error) {
                        //           console.error(error);
                        //         });
                        //     })
                        //     .catch(function (error) {
                        //       console.error(error);
                        //     });
                        // }
                      })
                      .catch((err) => console.log(err, "err"));
                    // }
                  }
                })
                .catch((err) => console.log(err, "err"));
            })
            .catch((err) => console.log(err, "err"));
        } else if (select === "free") {
          let user_metadata = userData?.user_metadata?.subscriptionPlan
            ? {
                subscriptionPlan: "Free",
                remainingCredits:
                  Number(process.env.REACT_APP_STRIPE_CREDITS_FREE) +
                  userData?.user_metadata?.remainingCredits,
                credits:
                  Number(process.env.REACT_APP_STRIPE_CREDITS_FREE) +
                  userData?.user_metadata?.credits,
                freeVersion: "claimed",
              }
            : {
                subscriptionPlan: "Free",
                remainingCredits: Number(
                  process.env.REACT_APP_STRIPE_CREDITS_FREE
                ),
                credits: Number(process.env.REACT_APP_STRIPE_CREDITS_FREE),
                freeVersion: "claimed",
              };
          // var params = { id: JSON.parse(localStorage.getItem("auth-Id")) };
          // if (userData?.user_metadata?.token) {
          var options = {
            method: "PATCH",
            url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
            headers: {
              authorization: `Bearer ${apiToken}`,
              "content-type": "application/json",
            },
            data: {
              user_metadata: {
                ...user_metadata,
                token: apiToken,
              },
            },
          };
          axios
            .request(options)
            .then(function (response) {
              setUserData(response.data);
              setApiCall2(true);
              navigation("/home");
            })
            .catch(function (error) {
              navigation("/subscription-plan");
              console.log(error);
              toast.error("invalid Error");
            });
          // } else {
          //   var options = {
          //     method: "post",
          //     url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
          //     headers: { "content-type": "application/json" },
          //     data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
          //   };
          //   axios(options)
          //     .then((res) => {
          //       var options = {
          //         method: "PATCH",
          //         url: `${
          //           process.env.REACT_APP_AUTH0_API_DOMAIN
          //         }/api/v2/users/${JSON.parse(localStorage.getItem("auth-Id"))}`,
          //         headers: {
          //           authorization: `Bearer ${res.data?.access_token}`,
          //           "content-type": "application/json",
          //         },
          //         data: {
          //           user_metadata: {
          //             ...user_metadata,
          //             token: res.data?.access_token,
          //           },
          //         },
          //       };
          //       axios
          //         .request(options)
          //         .then(function (response) {
          //           setUserData(response.data);
          //           setApiCall2(true);
          //           navigation("/home");
          //         })
          //         .catch(function (error) {
          //           console.error(error);
          //           toast.error("An Error occurred");
          //           navigation("/subscription-plan");
          //         });
          //     })
          //     .catch(function (error) {
          //       console.error(error);
          //     });
          // }
        }
      }
    }
    // stripe.prices
    //   .retrieve(jwt_decode(subscriptionPlan))
    //   .then((res) => {
    //     console.log(res, "res");
    //     let user_metadata = userData?.user_metadata?.subscriptionPlan
    //       ? {
    //           subscriptionPlan:
    //             50 === res?.unit_amount / 100
    //               ? "Starter"
    //               : 100 === res?.unit_amount / 100
    //               ? "Medium"
    //               : 200 === res?.unit_amount / 100
    //               ? "Pro"
    //               : null,
    //           remainingCredits:
    //             50 === res?.unit_amount / 100
    //               ? 1000 + userData?.user_metadata?.remainingCredits
    //               : 100 === res?.unit_amount / 100
    //               ? 10000 + userData?.user_metadata?.remainingCredits
    //               : 200 === res?.unit_amount / 100
    //               ? 50000 + userData?.user_metadata?.remainingCredits
    //               : null,
    //           credits:
    //             50 === res?.unit_amount / 100
    //               ? 1000 + userData?.user_metadata?.remainingCredits
    //               : 100 === res?.unit_amount / 100
    //               ? 10000 + userData?.user_metadata?.remainingCredits
    //               : 200 === res?.unit_amount / 100
    //               ? 50000 + userData?.user_metadata?.remainingCredits
    //               : null,
    //         }
    //       : {
    //           subscriptionPlan:
    //             50 === res?.unit_amount / 100
    //               ? "Starter"
    //               : 100 === res?.unit_amount / 100
    //               ? "Medium"
    //               : 200 === res?.unit_amount / 100
    //               ? "Pro"
    //               : null,
    //           remainingCredits:
    //             50 === res?.unit_amount / 100
    //               ? 1000
    //               : 100 === res?.unit_amount / 100
    //               ? 10000
    //               : 200 === res?.unit_amount / 100
    //               ? 50000
    //               : null,
    //           credits:
    //             50 === res?.unit_amount / 100
    //               ? 1000
    //               : 100 === res?.unit_amount / 100
    //               ? 10000
    //               : 200 === res?.unit_amount / 100
    //               ? 50000
    //               : null,
    //         };
    //     // var params = { id: JSON.parse(localStorage.getItem("auth-Id")) };
    //     var options = {
    //       method: "post",
    //       url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
    //       headers: { "content-type": "application/json" },
    //       data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
    //     };
    //     axios(options)
    //       .then((res) => {
    //         var options = {
    //           method: "PATCH",
    //           url: `${
    //             process.env.REACT_APP_AUTH0_API_DOMAIN
    //           }/api/v2/users/${JSON.parse(localStorage.getItem("auth-Id"))}`,
    //           headers: {
    //             authorization: `Bearer ${res.data?.access_token}`,
    //             "content-type": "application/json",
    //           },
    //           data: {
    //             user_metadata: user_metadata,
    //           },
    //         };

    //         axios
    //           .request(options)
    //           .then(function (response) {
    //             // console.log(response.data, "response.setUserData");
    //             setUserData(response.data);
    //             // setApiCall(!apiCall);
    //             // setLoader(false);
    //           })
    //           .catch(function (error) {
    //             console.error(error);
    //           });
    //       })
    //       .catch(function (error) {
    //         console.error(error);
    //       });
    //   })
    //   .catch((err) => console.log(err, "err"));
  }, [userData, localStorage.getItem("payment-intent"), apiToken]);
  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        setUserData={setUserData}
        apiCall={apiCall}
      />
      <div className="SubscriptionPlan">
        {/* <div className="text-innerText">
          <h1>Select Your Plan</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus
            possimus cumque aliquam laboriosam, <br />
            consequuntur omnis fuga. Sed, non. Laborum, rem officiis!
          </p>
        </div> */}
        <HorizontalStepper
          userData={userData}
          setApiCall={setApiCall}
          apiCall={apiCall}
          setUserData={setUserData}
          stepsNumber={2}
        />
      </div>
    </React.Fragment>
  );
}

export default StripePaymentauth;
