import React, { useState } from "react";
import HeaderComponent from "../Components/landingPage/headerComponent";
import ButtonComponent from "../Components/buttonComponent";
import AboutUs from "../Components/landingPage/aboutUs";
import FeaturesSection from "../Components/landingPage/featuresSection";
import FooterSection from "../Components/landingPage/footersection";
import SocailSlider from "../Components/landingPage/socailSlider";
import PricingSection from "../Components/landingPage/pricingsection";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function LandingPage(props) {
  const [socailMoadal, setSocailModal] = useState(false);
  const [socailSliderIndex, setSocailSliderIndex] = useState(0);
  const { loginWithRedirect } = useAuth0();

  const navigation = useNavigate();
  const handleClose = () => setSocailModal(false);
  const handleShow = (index) => {
    setSocailModal(true);
    setSocailSliderIndex(index);
  };
  return (
    <React.Fragment>
      <div className="LandingPage">
        <HeaderComponent
          navigate={navigation}
          setUserData={props?.setUserData}
          userData={props?.userData}
          url={props.url}
        />
        <div className="LandingPage-child" id="Home">
          <div className="inner-landingpage-child">
            <h3>
              A collection of growth tools to find any <br /> contact on any
              platform!
            </h3>
            <p>
              Get contacts fast. Search contacts on any platform including
              Tiktok, Instagram, <br /> Spotify, Spotify playlists, SoundCloud,
              YouTube and Twitter.
            </p>
            <div className="start-for-free">
              <ButtonComponent
                text={
                  props?.userData?.user_id
                    ? props?.userData?.user_metadata?.subscriptionPlan
                      ? "Go to dashboard"
                      : "Upgrade plan"
                    : "Start for free"
                }
                btnclass="btn-background mb-2 start-free-btn"
                onClick={() =>
                  props?.userData?.user_id
                    ? props?.userData?.user_metadata?.subscriptionPlan
                      ? navigation("/home")
                      : navigation("/subscription-plan")
                    : loginWithRedirect()
                }
              />
              <a href="#Pricing">
                {props?.userData?.user_id ? (
                  props?.userData?.user_metadata?.subscriptionPlan ? (
                    props?.userData?.user_metadata?.subscriptionPlan ===
                    "Pro" ? (
                      <img
                        src="/Assets/Images/landing/change plan.png"
                        // onClick={() => navigation("/subscription-plan")}
                        className="view-pricing"
                        alt=""
                      />
                    ) : (
                      <img
                        src="/Assets/Images/landing/update plan.png"
                        // onClick={() => navigation("/subscription-plan")}
                        className="view-pricing"
                        alt=""
                      />
                    )
                  ) : (
                    <img
                      src="/Assets/Images/landing/primary Button.png"
                      // onClick={() => navigation("/subscription-plan")}
                      className="view-pricing"
                      alt=""
                    />
                  )
                ) : (
                  <img
                    src="/Assets/Images/landing/primary Button.png"
                    // onClick={() => navigation("/subscription-plan")}
                    className="view-pricing"
                    alt=""
                  />
                )}
              </a>
            </div>
            <img src="/Assets/Images/landing/profile.png" alt="" />
          </div>
        </div>
        <AboutUs navigation={navigation} userData={props?.userData} />
        <FeaturesSection navigation={navigation} handleShow={handleShow} />
        <PricingSection
          packages={props?.packages}
          subscription={props?.subscription}
          navigation={navigation}
          handleShow={handleShow}
          userData={props?.userData}
        />
        <FooterSection />
      </div>
      <SocailSlider
        show={socailMoadal}
        handleClose={handleClose}
        selectedIndex={socailSliderIndex}
      />
    </React.Fragment>
  );
}
