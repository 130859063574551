import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputComponent from "./inputComponent";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendMessageModal from "./SendMessageModal";
import { useState, useEffect, useCallback } from "react";
import {
  LoginSocialInstagram,
  IResolveParams,
  LoginSocialTiktok,
} from "reactjs-social-login";
import axios from "axios";
import queryString from "query-string";
// import SC from "soundcloud";

const REDIRECT_URI = `${process.env.REACT_APP_DEPLOY_DOMAIN}/instagram-dashboard`;
// import { InstagramLoginButton } from "react-social-login-buttons";
export default function LinkIdModal({
  linkIdModal,
  changeLinkModalState,
  socailIcon,
  chatSocketUrl,
  userData,
  uid,
  cookieName,
  cookieName2,
  setsendMessageModal,
  pricingModal,
  getCookie,
}) {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const [open, setOpen] = React.useState(true);
  // const [sendMessageModal, setsendMessageModal] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    // setpricingModal(false)
  };

  const handleClose = () => {
    setOpen(false);
    changeLinkModalState();
  };

  const handleClick = () => {
    console.log("clicked");
    setOpen(false);
    setsendMessageModal(true);
    changeLinkModalState(false);
  };

  const changeSendMessageModal = () => {
    setsendMessageModal(false);
  };

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const onLogout = useCallback(() => {}, []);
  // useEffect(() => {
  //   if (linkIdModal) {
  //     if (localStorage.getItem("instagram")) {
  //       setsendMessageModal(true);
  //       changeLinkModalState();
  //       const csrfState = Math.random().toString(36).substring(2);
  //       axios(`${process.env.REACT_APP_API_URL}/instagram-signin`, {
  //         data: {
  //           user: "auth0|64ef0541fc675833282197c7",
  //           session_id:
  //             "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlBmNlQ3NFQ1anNTTXgtZWI3R2tLMSJ9.eyJpc3MiOiJodHRwczovL2VwcmVjaXNpby1zY3JhcHBlci5ldS5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjRlZjA1NDFmYzY3NTgzMzI4MjE5N2M3IiwiYXVkIjoiaHR0cHM6Ly9lcHJlY2lzaW8tc2NyYXBwZXIuZXUuYXV0aDAuY29tL2FwaS92Mi8iLCJpYXQiOjE2OTM2NDgxNzQsImV4cCI6MTY5MzY1NTM3NCwiYXpwIjoiRkYzRmVQOXBSREUzQXhmWjlTYUxiZ3lwRFU4WVp3UEMiLCJzY29wZSI6InJlYWQ6Y3VycmVudF91c2VyIHVwZGF0ZTpjdXJyZW50X3VzZXJfbWV0YWRhdGEifQ.PRzGTwUxiGB2QyaGNVzY9KzfTMfC-cQXVAllGTN6YfRtr540981lE_fdcN72oIlUa5PmPzrrpbPq6Y9ocvIyfdoTU0Got5-vV5HiOYFpktOAkfeY_uPLtIwIsVGTNet61C1g9aISrM-uev3PvccwTKfqN-GAtAx0SWpUJ6Di1ot_EpgfueDQDG5K4JgrAsWIWecnp0UZ4_giR5gyQT4ecIPQTin9lPxWD7UP_Cs3-5q-AzDxNhTs018Qq9oAlJiMp0QnlvnwuLnIx8j1ATp-nVFN_st9ltahprZBGXtY3MWWf4CAs7exHoK0MbVsP4qevT-zhFtMxTLPmIgn5Utwaw",
  //           csrftoken: csrfState,
  //         },
  //         method: "POST",
  //       })
  //         .then((res) => console.log(res))
  //         .catch((err) => console.log(err));
  //     } else if (localStorage.getItem("tiktok")) {
  //       setsendMessageModal(true);
  //       changeLinkModalState();
  //     }
  //   }
  // }, [linkIdModal]);
  // useEffect(() => {
  //   const urlSearchParams = new URLSearchParams(window.location.search);
  //   const code = urlSearchParams.get("code");
  //   if (socailIcon === "tiktok") {
  //     if (code) {
  //       authenticateWithTiktok(code);
  //     }
  //   }
  //   // else {
  //   //   authenticateWithInstagram(code);
  //   // }
  // }, []);
  const authenticateWithTiktok = async (code) => {
    const decode = decodeURI(code);
    const tokenEndpoint = "https://open.tiktokapis.com/v2/oauth/token/";
    const params = {
      client_key: "aw1o0rpnmfqsus8n",
      client_secret: "JtND6NvboRmffUwAzCsacL3SrQQc92Ps",
      code: decode,
      grant_type: "authorization_code",
      redirect_uri: "https://scrapper-4d7d8.web.app/callback",
    };
    axios
      .post(tokenEndpoint, queryString.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache",
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (pricingModal) {
      var cookie = getCookie(cookieName);
      var cookie2 = getCookie(cookieName2);
      console.log(cookie, "cookie");
      // window.location.href = window.location.href + "?DMChat=open";
      if (cookie && !cookieName2) {
        setsendMessageModal(true);
      } else if (cookie && cookie2) {
        setsendMessageModal(true);
      }
    }
  }, [document.cookie, pricingModal]);
  // const authenticateWithInstagram = async (code) => {
  //   axios
  //     .post("https://api.instagram.com/oauth/access_token", null, {
  //       params: {
  //         client_id: "319456120459722",
  //         client_secret: "d9b9dcbf47010667e947b26bd35b3083",
  //         grant_type: "authorization_code",
  //         redirect_uri: "https://scrapper-4d7d8.web.app/instagram-dashboard",
  //         code,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // function auth() {
  //   // SC.connect().then((session) => {
  //   //   fetchMe(session);
  //   // });
  //   axios(
  //     "https://api-auth.soundcloud.com/sign-in?client_id=iMxZgT5mfGstBj8GWJbYMvpzelS8ne0E",
  //     {
  //       // headers: {
  //       //   "Content-Type": "application/x-www-form-urlencoded",
  //       // },
  //       data: {
  //         credentials: {
  //           kind: "password",
  //           body: {
  //             identifier: "daniyalshehzad41@gmail.com",
  //             password: "daniyal",
  //           },
  //         },
  //       },
  //       method: "POST",
  //     }
  //   )
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));
  // }

  // function fetchMe(session) {
  //   fetch(`//api.soundcloud.com/me?oauth_token=${session.oauth_token}`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //     });
  // }
  const handleInstagramLogin = () => {
    // Redirect the user to Instagram's authorization URL
    window.location.href = `https://api.instagram.com/oauth/authorize
        ?client_id=319456120459722
        &redirect_uri=https://scrapper-4d7d8.web.app/instagram-dashboard
        &scope=user_profile,user_media
        &response_type=code`;
  };

  return (
    <div>
      {/* <ButtonComponent
        style={{
          height: "50px",
          whiteSpace: "nowrap",
          borderRadius: "10px",
          width: "150px",
        }}
        text={"Next"}
        btnclass="btn-background"
        onClick={handleClick}
      /> */}
      {/* <img onClick={handleClickOpen} src="./Assets/Images/edit.png" alt="editIcon" width={30} className="editBtn"/> */}

      <Dialog
        open={linkIdModal}
        onClose={handleClose}
        id="social-platforms-dms"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: "400px" }}
        // fullScreen={"md"}
        PaperProps={{
          sx: { borderRadius: "15px", minWidth: "400px", textAlign: "center" },
        }}
      >
        <div className="cross-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        <img
          src={
            socailIcon === "twitter"
              ? "./Assets/Images/landing/socail/twitter.webp"
              : socailIcon === "tiktok"
              ? "./Assets/Images/tiktokLink.png"
              : socailIcon === "soundclooud"
              ? "./Assets/Images/sound.png"
              : "./Assets/Images/Instragramicon.png"
          }
          alt=""
          className="linkImage"
        />
        <a
          className="btn-background btn-main social-Button mb-2 download-extinction-href"
          style={{ textDecoration: "none" }}
          href="https://chromewebstore.google.com/detail/findsocial-login-extensio/lffkapidlaihpombjliljccbcjkcenki?hl=en-US&utm_source=ext_sidebar"
          target="_blank"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              // window.open(
              //   "https://chromewebstore.google.com/detail/findsocial-login-extensio/lffkapidlaihpombjliljccbcjkcenki?hl=en-US&utm_source=ext_sidebar",
              //   "_blank"
              // );
              setTimeout(() => {
                window.addEventListener('focus', function() {
                console.log('Iskander: user is back');
                window.location.reload();
                });
              }, 2000);
            }}
          >
            Download extension
          </div>
        </a>
        {/* <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
        >
          Coming soon
        </DialogTitle> */}

        {/* <DialogContent>
          <p className="social-account-name">TikToK ID</p>
          <div style={{ position: "relative" }}>
            <InputComponent
              classinput="input-Stripe"
              // value={cardNumber}
              placeholder="Profile"
            />
            <MailOutlineIcon className="mail-icon" />
            <button
              onClick={() => {
                const csrfState = Math.random().toString(36).substring(2);
                // window.cookie("csrfState", csrfState, { maxAge: 60000 });
                let url = "https://www.tiktok.com/v2/auth/authorize/";
                // the following params need to be in `application/x-www-form-urlencoded` format.
                url += "?client_key=aw1o0rpnmfqsus8n";
                url += "&scope=user.info.basic";
                url += "&response_type=code";
                url +=
                  "&redirect_uri=https://scrapper-4d7d8.web.app/authConnect";
                // url += "&state=" + csrfState;
                window.location.href = url;
              }}
            >
              Tiktok
            </button>
          </div>
        </DialogContent> */}
        <div>
          {/* <ButtonComponent
            style={{
              height: "45px",
              width: "95%",
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "10px",
            }}
            onClick={handleClick}
            text={`Link with ${socailIcon}`}
            btnclass="btn-background"
          /> */}
          {/* <Button className="btn-background btn-background-link btn-main social-Button mb-2">
            Coming Soon
          </Button> */}
          {/* {socailIcon === "tiktok" ? (
            <LoginSocialTiktok
              client_key={"aw1o0rpnmfqsus8n"}
              // client_id={"aw1o0rpnmfqsus8n"}
              // client_secret={"JtND6NvboRmffUwAzCsacL3SrQQc92Ps"}
              redirect_uri={"https://scrapper-4d7d8.web.app/callback"}
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              className="btn-background btn-main social-Button"
              onResolve={({ provider, data }) => {
                setProvider(provider);
                setProfile(data);
                const csrfState = Math.random().toString(36).substring(2);

                axios(`${process.env.REACT_APP_API_URL}/tiktok-signin`, {
                  data: {
                    user: userData?.id,
                    session_id: data?.access_token,
                  },
                  method: "POST",
                })
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
                localStorage.setItem("tiktok", data?.access_token);
                localStorage.setItem("provider", provider);
                window.close();
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              Link with Tiktok
            </LoginSocialTiktok>
          ) : socailIcon === "soundclooud" ? (
            <Button className="btn-background btn-main social-Button mb-2">
              Link with SoundCloud
            </Button>
          ) : (
            <LoginSocialInstagram
              client_id={"319456120459722"}
              client_secret={"d9b9dcbf47010667e947b26bd35b3083"}
              redirect_uri={
                "https://scrapper-4d7d8.web.app/instagram-dashboard"
              }
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              response_type="code"
              scope="user_profile,user_media"
              className="btn-background btn-main social-Button sound-cloud-background"
              onResolve={({ provider, data }) => {
                console.log(provider, data, "provider-,-data");
                setProvider(provider);
                setProfile(data);
                const csrfState = Math.random().toString(36).substring(2);

                axios(`${process.env.REACT_APP_API_URL}/instagram-signin`, {
                  data: {
                    user: userData?.id,
                    session_id: data?.access_token,
                    csrftoken: csrfState,
                  },
                  method: "POST",
                })
                  .then((res) => console.log(res))
                  .catch((err) => console.log(err));
                localStorage.setItem("instagram", data?.access_token);
                localStorage.setItem("instagram", data?.access_token);
                localStorage.setItem("provider", provider);
                window.close();
              }}
              onReject={(err) => {
                console.log(err, "errerrerrerrerrerrerr");
              }}
            >
              Link with Instagram
            </LoginSocialInstagram>
          )} */}
        </div>
      </Dialog>
    </div>
  );
}
