import React from "react";
import FooterComponent from "../footerComponent";
import PricingComponent from "../../UI/PricingComponent";
import BasicTabs from "../BasicTabs";
export default function PricingSection({ userData, packages, subscription }) {
  const [selectCurrency, setSelectCurrency] = React.useState("USD");
  const [currencyDropdownDisabled, setCurrencyDropdownDisabled] =
    React.useState(false);
  React.useEffect(() => {
    if (subscription?.length) {
      setSelectCurrency(subscription[0]?.currency.toUpperCase());
      setCurrencyDropdownDisabled(true);
    }
  }, [subscription]);
  return (
    <React.Fragment>
      <div className="pricing-cards-parent " id="Pricing">
        <div className="subscription-plan-inner">
          <h1>Pricing & Plan</h1>
          <p>
            FindSocial offers free and premium plans to give you a hassle free
            <br />
            and seamless experience, preventing any loss of information.
          </p>
        </div>
      </div>
      <div className="landing-page-tab">
        <BasicTabs
          userData={userData}
          packages={packages}
          currencyDropdownDisabled={currencyDropdownDisabled}
          selectCurrency={selectCurrency}
          setSelectCurrency={setSelectCurrency}
        />
      </div>
    </React.Fragment>
  );
}
