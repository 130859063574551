import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginForm from "../Components/loginFormx";
import { useState } from "react";
import { webAuth } from "../Data/Auth/webAuth";
import { toast } from "react-toastify";
import FooterComponent from "../Components/footerComponent";
import LandingHeader from "../Components/LandingHeader";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthSwiper from "./authSwiper";
function LoginComponent(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [object, setObject] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    document.documentElement.style.setProperty("--swiper-theme-color", "#000");
    document.documentElement.style.setProperty(
      "--swiper-navigation-size",
      "22px"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      "#fff"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-bullet-inactive-color",
      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)"
    );
  }, []);
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/home");
    }
  }, [localStorage.getItem("accessToken")]);
  const loginUser = async () => {
    webAuth.login(
      {
        realm: `Username-Password-Authentication`,
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
        // username: object?.email,
        email: object?.email,
        password: object?.password,
      },
      function (err, authResult) {
        if (err) {
          console.log(err);
          setLoader(false);
          return toast.error(err?.error_description || "Invalid error");
        }
        console.log(authResult, "authResult");
        if (authResult) {
          if (authResult && authResult.accessToken) {
            webAuth.client.userInfo(
              authResult.accessToken,
              (error, profile) => {
                if (error) {
                  console.log("Error loading the Profile", error);
                } else {
                  // console.log(profile, "profile");
                  // console.log(authResult, "authResult");
                }
              }
            );
          }
        }
      }
    );
  };
  let testEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleChange = (ev) => {
    ev.preventDefault();
    if (!loader && testEmail.test(object?.email) && object?.password.length) {
      setLoader(true);
      loginUser();
    } else {
      alert("Your email or password is invalid");
    }
  };
  const facebookLogin = () => {
    setLoader(true);
    webAuth.authorize(
      {
        connection: "facebook",
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
      },
      function (err, result) {
        if (err) {
          console.log(err);
          return toast.error(err?.error_description || "Invalid error");
        }
        if (result) {
          // console.log(result);
        }
      }
    );
  };
  const googleLogin = () => {
    setLoader(true);
    webAuth.authorize(
      {
        connection: "google-oauth2",
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
      },
      function (err, result) {
        if (err) {
          console.log(err);
          return toast.error(err?.error_description || "Invalid error");
        }
        if (result) {
          // console.log(result);
        }
      }
    );
  };
  return (
    <React.Fragment>
      <div className="LoginComponent mainComponent">
        {/* <div className="main-header">
          <Link className="navbar-brand" to="/home">
            <img src="/Assets/Images/logo.jpeg" className="img-logo" alt="" />
            <img src="/Assets/Images/logo.jpeg" className="res-Img" alt="" />
          </Link>
        </div> */}
        {/* <LandingHeader /> */}
        <div className="section-2">
          <LoginForm
            text={"Login"}
            loader={loader}
            setLoader={setLoader}
            handleChange={handleChange}
            object={object}
            setObject={setObject}
            facebookLogin={facebookLogin}
            googleLogin={googleLogin}
            navigate={navigate}
          />
        </div>
        <div className="section-1">
          {/* <img className="dotsImage" src="./Assets/Images/dots.png" alt="" srcset="" /> */}
          {/* <Carousel
             showArrows={true}
             infiniteLoop={true}
             showThumbs={false}
             showStatus={false}
             autoPlay={true}
             interval={6100}
          >
            <div>
              <img src="assets/Images/slider1.png"  width="20%"/>
              <h1  className="slider-heading">Heading</h1>
              <div className="slider-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. At, natus!</div>
            </div>
            <div>
              <img src="assets/Images/slider2.png" width="20%" />
              <h1 className="slider-heading">Heading</h1>
              <p className="slider-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. At, natus!</p>
            </div>
            <div>
              <img src="assets/Images/slider3.png"  width="20%"/>
              <h1 className="slider-heading">Heading</h1>
              <p className="slider-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. At, natus!</p>
            </div>
         </Carousel> */}
         <div className="auth-slider-component-parent">
          <AuthSwiper />
         </div>
          {/* <Swiper
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div>
                <img src="assets/Images/slider1.png" />
                <h1 className="slider-heading">Heading</h1>
                <div className="slider-text">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. At,
                  natus!
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="assets/Images/slider2.png" />
                <h1 className="slider-heading">Heading</h1>
                <p className="slider-text">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. At,
                  natus!
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src="assets/Images/slider3.png" />
                <h1 className="slider-heading">Heading</h1>
                <p className="slider-text">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. At,
                  natus!
                </p>
              </div>
            </SwiperSlide>
          </Swiper> */}
          {/* <img className="circleImage" src="./Assets/Images/circle.png" alt="" srcset="" /> */}
        </div>
      </div>
      {/* <FooterComponent background={true} /> */}
    </React.Fragment>
  );
}

export default LoginComponent;
