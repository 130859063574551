import React, { useState } from "react";
import { useDeepSearch } from "../../store/useDeepSearch";

function DeepSearch({ platform }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
  const { deepSearch, setDeepSearch, setDeepSearchToggle } = useDeepSearch();

  const handleToggle = () => {
    setDeepSearch();

    if (!deepSearch === false) {
      setDeepSearchToggle(false);
    }
  };

  return (
    <div className="border-filter">
      <div
        className="filter-menu-label"
        onClick={toggleContent}
        style={{ cursor: "pointer" }}
      >
        <div>Deep search</div>
        {platform == "YouTube" ||
        platform == "Spotify artists" ||
        platform == "Spotify playlists" ? (
          "Coming soon"
        ) : (
          <div className="toggle-switch">
            <input
              type="checkbox"
              id="toggle"
              className="toggle-checkbox"
              checked={deepSearch}
              onChange={handleToggle}
            />
            <label htmlFor="toggle" className="toggle-label">
              <span className="toggle-inner" />
              <span className="toggle-switch" />
            </label>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
          marginTop: 16,
        }}
      >
        <div>
          <p className="heading" style={{ color: "#878787" }}>
            Generate information using our cross-platform search.
          </p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            color: "#878787",
            fontSize: "14px",
          }}
        >
          <label>Credit limit</label>
          <input type="number" placeholder="0" className="filter-menu-input" />
        </div>
      </div>
    </div>
  );
}

export default DeepSearch;
