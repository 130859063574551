import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputComponent from "./inputComponent";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import StripeComponentModal from "./StripeComponentModal";
import PricingModal from "./PricingModal";
import LinkIdModal from "./LinkIdModal";
import { useNavigate } from "react-router-dom";
import SendMessageModal from "./SendMessageModal";
import { Spinner } from "react-bootstrap";

export default function UpgradeModal({
  userData,
  cookieName2,
  setApiCall,
  name,
  apiCall,
  setUserData,
  premium,
  routesname,
  socailIcon,
  chatSocketUrl,
  disabledButton,
  chatAPItUrl,
  uid,
  cookieName,
  loader,
  setLoader,
  // upgradeModal,
  // setupgradeModal,
  loaderMsgId,
  setLoaderMsgId,
  props,
}) {
  var url = new URL(window.location);
  let openMessage = url.searchParams.get("open");
  const [open, setOpen] = useState(false);
  const [upgradeModal, setupgradeModal] = useState(false);
  const [pricingModal, setpricingModal] = useState(false);
  const [linkIdModal, setlinkIdModal] = useState(false);
  const [sendMessageModal, setsendMessageModal] = useState(false);
  const [] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setupgradeModal(false);
    navigate(window.location.pathname);
  };
  // useEffect(() => {
  //   if (!upgradeModal) {
  //     if (openMessage === "message") {
  //       console.log("open message");
  //       var cookie = getCookie(cookieName);
  //       var cookie2 = getCookie(cookieName2);
  //       if (!premium) {
  //         setupgradeModal(true);
  //       } else if (premium) {
  //         if (cookie && !cookieName2) {
  //           setsendMessageModal(true);
  //         } else if (cookie && cookie2) {
  //           setsendMessageModal(true);
  //         } else {
  //           setlinkIdModal(true);
  //         }
  //       }
  //     }
  //   }
  //   return () => {
  //     setupgradeModal(false);
  //   };
  // }, []);
  const changeUpgradeModal = (state) => {
    setupgradeModal(false);
    setpricingModal(state);
  };
  const changeLinkModalState = () => {
    setlinkIdModal(false);
    navigate(window.location.pathname);
    console.log("linkidmodal", linkIdModal);
  };

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  useEffect(() => {
    if (pricingModal) {
      var cookie = getCookie(cookieName);
      var cookie2 = getCookie(cookieName2);
      // window.location.href = window.location.href + "?DMChat=open";
      if (cookie && !cookieName2) {
        setsendMessageModal(true);
      } else if (cookie && cookie2) {
        setsendMessageModal(true);
      }
    }
  }, [document.cookie, pricingModal]);
  const changeSendMessageModal = () => {
    setsendMessageModal(false);
  };
  const openLinkModal = () => {
    setlinkIdModal(true);
    changeUpgradeModal(false);
  };
  const openlinkModal = () => {
    setpricingModal(false);
    setlinkIdModal(true);
  };
  const handleClose2 = () => {};
  return (
    <div>
      <div
        className="Send-message-parent"
        style={{
          cursor:
            loaderMsgId == uid && loader
              ? "progress"
              : loader
              ? "not-allowed"
              : "pointer",
        }}
        onClick={() => {
          var cookie = getCookie(cookieName);
          var cookie2 = getCookie(cookieName2);
          // window.location.href = window.location.href + "?DMChat=open";
          if (!loader) {
            if (!disabledButton) {
              if (!premium) {
                setupgradeModal(true);
              } else if (premium) {
                if (cookie && !cookieName2) {
                  setsendMessageModal(true);
                } else if (cookie && cookie2) {
                  setsendMessageModal(true);
                } else {
                  setlinkIdModal(true);
                }
              }
            }
          }
        }}
      >
        {loaderMsgId == uid && loader ? (
          <Spinner
            className="Send-message-loader"
            style={{ width: 25, height: 25, color: "#01c38d" }}
          />
        ) : (
          <img
            src={"./Assets/Images/components/Sendmsg.png"}
            width={50}
            alt="Player"
            style={disabledButton || loader ? { opacity: 0.5 } : { opacity: 1 }}
            className={props?.class}
          />
        )}
      </div>
      {/* <img onClick={handleClickOpen} src="./Assets/Images/edit.png" alt="editIcon" width={30} className="editBtn"/> */}
      <Dialog
        open={upgradeModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullScreen={"md"}
        PaperProps={{
          sx: { borderRadius: "15px", minWidth: "400px", textAlign: "center" },
        }}
      >
        <div
          className="cross-button"
          onClick={() => {
            // console.log(window.location);
            // window.location.href = window.location;
            setupgradeModal(false);
            navigate(window.location.pathname);
            console.log(window.location.pathname);
            // navigate(window.location.pathname);
          }}
        >
          <CloseIcon />
        </div>
        <img
          src="/Assets/Images/alert.png"
          alt=""
          //   width={100}
          className="linkImage"
        />
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
        >
          {"Upgrade to premium"}
        </DialogTitle>
        <DialogContent>
          Get a premium subscription and enjoy using FindSocial
        </DialogContent>
        <ButtonComponent
          onClick={() => {
            console.log(routesname);
            navigate(
              `/subscription-plan?redirect=${routesname}-dashboard&open=message`
            );
          }}
          style={{
            height: "45px",
            width: "95%",
            whiteSpace: "nowrap",
            borderRadius: "10px",
            margin: "8px",
          }}
          text={"View plans"}
          btnclass="btn-background"
        />
      </Dialog>
      <PricingModal
        changeUpgradeModal={changeUpgradeModal}
        pricingModal={pricingModal}
        userData={userData}
        handleClose={handleClose2}
        // setApiCall={setApiCall}
        // apiCall={apiCall}
        sendMessageModal={sendMessageModal}
        setsendMessageModal={setsendMessageModal}
        setUserData={setUserData}
        openlinkModal={openlinkModal}
      />
      <LinkIdModal
        uid={uid}
        pricingModal={pricingModal}
        userData={userData}
        chatSocketUrl={chatSocketUrl}
        cookieName2={cookieName2}
        setsendMessageModal={setsendMessageModal}
        cookieName={cookieName}
        socailIcon={socailIcon}
        getCookie={getCookie}
        linkIdModal={linkIdModal}
        openLinkModal={openLinkModal}
        changeLinkModalState={changeLinkModalState}
      />
      <SendMessageModal
        loader={loader}
        setLoader={setLoader}
        socailIcon={socailIcon}
        getCookie={getCookie}
        cookieName={cookieName}
        cookieName2={cookieName2}
        chatSocketUrl={chatSocketUrl}
        userData={userData}
        chatAPItUrl={chatAPItUrl}
        uid={uid}
        sendMessageModal={sendMessageModal}
        changeSendMessageModal={changeSendMessageModal}
        setLoaderMsgId={setLoaderMsgId}
        loaderMsgId={loaderMsgId}
      />
    </div>
  );
}
