import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AboutUsComponent from "../UI/aboutUsComponent";
import PrivacyPolicyComponent from "../UI/privacyPolicyComponent";
import TermsAndConditions from "../UI/termsAndConditions";
export default function FooterComponent({ background }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const handleClose = () => setModalOpen(false);
  const handleClose2 = () => setModalOpen2(false);
  const handleClose3 = () => setModalOpen3(false);
  return (
    <>
      <footer
        className="footer"
        style={background ? {} : { backgroundColor: "#fff" }}
      >
        <div className="footer-child">
          <div
            className="footer-child-text"
            onClick={() => setModalOpen(!modalOpen)}
          >
            Terms and conditions
          </div>
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>|</div>
          <div
            className="footer-child-text"
            onClick={() => setModalOpen2(!modalOpen2)}
          >
            Privacy policy
          </div>
          {/* <div style={{ marginLeft: "10px", marginRight: "10px" }}>|</div> */}
          {/* <div
            className="footer-child-text"
            onClick={() => setModalOpen3(!modalOpen3)}
          >
            About us
          </div> */}
          {/* <div style={{ marginleft: "10px" }}>|</div> */}
        </div>
      </footer>
      <Modal show={modalOpen} onHide={handleClose} className="modal-component modal-terms-conditions">
      <Modal.Header closeButton></Modal.Header>
        <TermsAndConditions />
      </Modal>
      <Modal
        show={modalOpen2}
        onHide={handleClose2}
        className="modal-component"
      >
        <PrivacyPolicyComponent />
      </Modal>
      <Modal
        show={modalOpen3}
        onHide={handleClose3}
        className="modal-component"
      >
        <AboutUsComponent />
      </Modal>
    </>
  );
}
