import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComponent from "../Components/buttonComponent";

function ModalUI({ show, handleClose, handleShow, navigation }) {
  const [artists, setArtists] = useState(false);
  return (
    <React.Fragment>
      {/* <Button
        className="select button-primary tabs-button-primary"
        onClick={() => {
          if (userData?.user_id) {
            handleShow();
          } else {
            toast.error("You are not login");
          }
        }}
      >
        <div className="select-margin-items spotify">
          <img
            src="/Assets/Images/socailMedia/spotify.png"
            className="rounded-circle s1"
            alt=""
            width="100px"
            height="100px"
            // style={{ backgroundColor: "#1ed75f41" }}
          />
          <h5 className="h5-div-heading tabs-a">Spotify</h5>
          <p className="hovering tabs-a">
            Easily finding artist or playlist contact information from Spotify,
            enabling musicians, record labels, agencies and playlist curators to
            connect, collaborate, and expand their networks with just a few
            clicks, all in one user-friendly platform.
          </p>
        </div>
      </Button> */}
      <Modal show={show} onHide={handleClose} className="modal-show-shopify">
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="mobel-float">
                  <Button className="mobel-button-close" onClick={handleClose}>
                    <i className="fa-solid fa-xmark mobel-button-i"></i>
                  </Button>
                </div>
                <br />
                <div className="">
                  <div className="select-margin-items spotify">
                    <img
                      src="./Assets/Images/landing/socail/shopify.png"
                      className="rounded-circle model-select"
                      alt=""
                      width="100px"
                      height="100px"
                    />
                    <h5 className="h5-div-heading tabs-a">
                      <br />
                      Spotify
                    </h5>
                  </div>
                  <h1 className="model-search-by">Search</h1>
                  <Link
                    onClick={() => {
                      setArtists(0);
                      // navigation("/spotify-playlist-dashboard");
                    }}
                    to={"/spotifyplaylist-dashboard"}
                    className={`model-width-margin-bottom model-heading-h1 ${
                      artists === 0 ? "active-modal-button" : ""
                    } `}
                  >
                    <div className="active-modal-child">
                      <span>Playlist</span>
                      {/* <i
                        className="fa-sharp fa-solid fa-circle-exclamation mobel-button-exclamation"
                        title="Spotify"
                      ></i> */}
                    </div>
                  </Link>
                  <Link
                    onClick={() => {
                      setArtists(1);
                      // navigation("/spotify-artists-dashboard");
                    }}
                    to={"/spotifyartists-dashboard"}
                    className={`model-width-margin-bottom model-heading-h1 ${
                      artists === 1 ? "active-modal-button" : ""
                    } `}
                  >
                    <div className="active-modal-child">
                      <span>Artist</span>
                      {/* <i
                        className="fa-sharp fa-solid fa-circle-exclamation mobel-button-exclamation"
                        title="Spotify"
                      ></i> */}
                    </div>
                  </Link>
                  {/* <ButtonComponent
                    text={"Next"}
                    onClick={() => {
                      if (artists !== false) {
                        navigation(
                          artists === 0
                            ? "/spotify-playlist-dashboard"
                            : artists === 1
                            ? "/spotify-artists-dashboard"
                            : null
                        );
                      }
                    }}
                    btnclass="btn-background mb-2 socail-spotify-button"
                  /> */}
                  {/* <Link to="/spotify-instagram-link-dashboard" style={{textDecoration: 'none'}} className="model-width-margin-bottom model-heading-h1" >
                                    Search by Instagram Links
                                    <i className="fa-sharp fa-solid fa-circle-exclamation mobel-button-exclamation" title="Spotify"></i>
                                </Link> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ModalUI;
