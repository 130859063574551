import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../Components/footerComponent";
import SubscriptionCards from "../Components/subscriptionCards";
import HeaderUI from "./HeaderUI";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import HorizontalStepper from "../Components/HorizontalStepper";
export default function SubscriptionPlan({
  userData,
  setApiCall,
  apiCall,
  setUserData,
  subscription,
  packages,
  isAuthenticated,
  isLoading,
}) {
  const sign = require("jwt-encode");
  const stripe = require("stripe")(process.env.REACT_APP_STRIPE_CLIENT_SECRET);
  const navigation = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("payment-intent")) {
      localStorage.removeItem("payment-intent");
    }
  }, []);
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigation("/home");
    }
  }, [isAuthenticated, isLoading]);
  // const handleChange = (id, price) => {
  //   // console.log(id, "id");
  //   // const secret = "456789";
  //   // const data = id;
  //   // const jwt = sign(data, secret, {});
  //   setPricingData({
  //     id: id,
  //     price: price,
  //   });
  //   // if (userData) {
  //   //   let obj =
  //   //     price === 0
  //   //       ? {
  //   //           mode: "subscription",
  //   //           line_items: [{ price: id, quantity: 1 }],
  //   //           // customer_email: userData?.email,
  //   //           subscription_data: {
  //   //             // trial_settings: {
  //   //             //   end_behavior: { missing_payment_method: "cancel" },
  //   //             // },
  //   //             trial_period_days: 1,
  //   //             // items: [{ plan: id, customer_email: userData?.email }],
  //   //           },
  //   //           customer: JSON.parse(localStorage.getItem("stripe_customer_id")),
  //   //           // payment_method_collection: "if_required",
  //   //           // trial_settings: {
  //   //           //   end_behavior: { missing_payment_method: "cancel" },
  //   //           // },
  //   //           // discounts: [{ coupon: "nPKlHzZT" }],
  //   //           metadata: {
  //   //             customerId: JSON.parse(
  //   //               localStorage.getItem("stripe_customer_id")
  //   //             ),
  //   //             productId: id,
  //   //           },
  //   //           payment_method_types: ["card"],
  //   //           success_url: process.env.REACT_APP_DEPLOY_DOMAIN + "/stripe-auth",
  //   //           cancel_url: `${process.env.REACT_APP_DEPLOY_DOMAIN}/subscription-plan`,
  //   //         }
  //   //       : {

  //   //           customer: JSON.parse(localStorage.getItem("stripe_customer_id")),
  //   //           success_url: process.env.REACT_APP_DEPLOY_DOMAIN + "/stripe-auth",
  //   //           line_items: [{ price: id, quantity: 1 }],
  //   //           mode: "subscription",
  //   //           // customer: JSON.parse(localStorage.getItem("stripe_customer_id")),
  //   //           metadata: {
  //   //             customerId: JSON.parse(
  //   //               localStorage.getItem("stripe_customer_id")
  //   //             ),
  //   //             productId: id,
  //   //           },
  //   //           allow_promotion_codes: true,
  //   //           // after_completion: {
  //   //           //   type: "redirect",
  //   //           //   redirect: {
  //   //           //     url:
  //   //           //       process.env.REACT_APP_DEPLOY_DOMAIN +
  //   //           //       "/stripe-auth?planID=" +
  //   //           //       jwt,
  //   //           //   },
  //   //           // },
  //   //           payment_method_types: ["card"],
  //   //           // success_url: process.env.REACT_APP_DEPLOY_DOMAIN + "/stripe-auth",
  //   //           cancel_url: `${process.env.REACT_APP_DEPLOY_DOMAIN}/subscription-plan`,
  //   //         };
  //   //   stripe.checkout.sessions
  //   //     .create(obj)
  //   //     .then((res) => {
  //   //       // console.log(res);
  //   //       localStorage.setItem("payment-intent", res?.id);
  //   //       window.location.assign(res.url);
  //   //     })
  //   //     .catch((err) => {
  //   //       console.log(err);
  //   //     });
  //   // }
  // };
  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div className="SubscriptionPlan">
        <div className="text-innerText text-center">
          <h1>Select Your Plan</h1>
          <p>
            Welcome to our platform! We offer a range of plans tailored to meet
            your specific needs. Whether you're an individual looking for basic
            <br />
            features or a business in search of comprehensive solutions, we have
            the right plan for you. Choose from the options below to get started.
          </p>
        </div>
        <HorizontalStepper
          packages={packages}
          userData={userData}
          navigate={navigation}
          setApiCall={setApiCall}
          apiCall={apiCall}
          setUserData={setUserData}
          subscription={subscription}
        />
      </div>
    </React.Fragment>
  );
}
