import { create } from "zustand";

export const useDeepSearch = create((set, get) => ({
  deepSearchToggle: false,
  deepSearch: false,
  showModal: false,
  selectedRow: null,
  setShowModal: () => {
    const { showModal } = get();
    set({ showModal: !showModal });
  },

  setDeepSearchFalse: () => {
    set({ deepSearch: false });
  },
  setDeepSearch: (value) => {
    const { deepSearch } = get();
    set({ deepSearch: !deepSearch });
  },
  setDeepSearchToggle: (value) => {
    set({ deepSearchToggle: value });
  },

  handleRowClicked: (row) => {
    const { showModal } = get();

    if (row?.deep_search) {
      console.log(row?.deep_search, "dad");
      set({ selectedRow: row?.deep_search });
      set({ showModal: !showModal });
      console.log(!showModal, "sas");
    }
  },
}));
