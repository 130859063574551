import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginForm from "../Components/loginFormx";
import { useState } from "react";
import { webAuth } from "../Data/Auth/webAuth";
import FooterComponent from "../Components/footerComponent";
import ButtonComponent from "../Components/buttonComponent";
import InputComponent from "../Components/inputComponent";
import { toast } from "react-toastify";
import InprogressComponent from "../Components/inprogressComponent";
import LandingHeader from "../Components/LandingHeader";
import AuthSwiper from "./authSwiper";
function ForgetComponent(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (props?.userData?.user_id) {
      navigate("/home");
    }
  }, [props?.userData]);
  let testEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleChange = (ev) => {
    ev.preventDefault();
    if (!email.match(testEmail)) {
      toast.error("Invalid Email");
      return;
    } else {
      setLoader(true);
      webAuth.changePassword(
        {
          email: email,
          // realm: `Username-Password-Authentication`,
          connection: `Username-Password-Authentication`,
          // redirectUri: `${props?.url}/account-overview`,
        },
        function (err, resp) {
          if (err) {
            toast.error(err.message);
            setLoader(false);
            setEmail("");
          } else {
            toast.success(resp);
            setEmail("");
            setLoader(false);
            //   console.log(resp);
          }
        }
      );
    }
  };
  return (
    <React.Fragment>
      <div className="LoginComponent mainComponent">
        <LandingHeader />
        {/* <div className="section-1">
          <div className="header-text">Try FindSocial now.</div>
          <p className="para-text">
            Find any contact from platforms such as TikTok, Spotify artists,
            Spotify playlists, SoundCloud, Instagram, YouTube and more.
          </p>
          <div className="ipadImg-parent">
            <img className="ipadImg" src="/Assets/Images/ipadPro.png" />
          </div>
        </div> */}
        <div className="section-2">
          <div
            className="form-main forgot-password-form-main"
            style={loader ? { justifyContent: "center" } : {}}
            // onSubmit={handleChange}
          >
            {loader && (
              <InprogressComponent
                headerText={"processing"}
                paraText={"Please wait while the page is loading."}
                loader={props.loader}
              />
            )}
            {!loader && (
              <>
                <div className="header-Text">Forget Password</div>
                <form style={{ width: "100%" }} onSubmit={handleChange}>
                  <InputComponent
                    value={email}
                    classinput={"inputBg"}
                    placeholder={"email"}
                    type={"email"}
                    required={true}
                    onChange={(ev) => setEmail(ev.target?.value)}
                  />
                  <ButtonComponent
                    text={"send"}
                    btnclass="btn-background mb-2"
                  />
                </form>
                <p>
                  go Back to <Link to={"/login"}>login</Link>
                </p>
              </>
            )}
          </div>
        </div>
        <div className="section-1">
          <div className="auth-slider-component-parent">
            <AuthSwiper />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ForgetComponent;
