import auth0 from "auth0-js";
export const webAuth = new auth0.WebAuth({
  domain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
  scope:
    "read:current_user read:users_app_metadata update:users_app_metadata   update:current_user_metadata",
});
export const authentication = new auth0.Authentication({
  domain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
});
