import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ButtonComponent from "./buttonComponent";
function UpdateModal({ show, handleClose, changeUpgradeModal, onNext }) {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // fullScreen={"md"}
      PaperProps={{
        sx: { borderRadius: "15px", minWidth: "400px", textAlign: "center" },
      }}
    >
      <div className="cross-button" onClick={() => handleClose()}>
        <CloseIcon />
      </div>
      <img
        src="/Assets/Images/alert.png"
        alt=""
        //   width={100}
        className="linkImage"
      />
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
      >
        {"Upgrade to premium"}
      </DialogTitle>
      <DialogContent>
        Get a premium subscription and enjoy using FindSocial
      </DialogContent>
      <ButtonComponent
        onClick={onNext}
        style={{
          height: "45px",
          width: "95%",
          whiteSpace: "nowrap",
          borderRadius: "10px",
          margin: "8px",
        }}
        text={"View plans"}
        btnclass="btn-background"
      />
    </Dialog>
  );
}
export default UpdateModal;
