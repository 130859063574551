import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputComponent from "./inputComponent";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { Spinner } from "react-bootstrap";

export default function ForgotPasswordModal({
  open,
  handleClose,
  changePasswordSendMail,
  forgotPasswordLoader,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: "400px" } }}
    >
      <div className="cross-button" onClick={handleClose}>
        <CloseIcon />
      </div>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
      >
        {"Confirmation"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          Are you sure you want to request a password reset link?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <img
          src="./Assets/Images/NoBtn.png"
          alt="Cancel"
          className="cancel-button"
          onClick={handleClose}
        />

        <ButtonComponent
          style={{
            height: "50px",
            whiteSpace: "nowrap",
            borderRadius: "10px",
            margin: "10px",
            width: "150px",
          }}
          text={forgotPasswordLoader ? <Spinner color="#fff" /> : "Confirm"}
          // text={"Confirm"}
          onClick={changePasswordSendMail}
          btnclass="btn-background"
        />
      </DialogActions>
    </Dialog>
  );
}
