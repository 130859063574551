import React, { useState } from "react";
import ButtonComponent from "../buttonComponent";
import TermsAndConditions from "../../UI/termsAndConditions";
import { Modal } from "react-bootstrap";
import CustomForm from "../../config/email";

export default function FooterSection() {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);

  return (
    <>
      <div className="footer-section">
        <div className="inner-footer-section">
          <div className="footer-section-child-1">
            <img src="./Assets/Images/landing/footer/logo.png" alt="" />
            <p>
              FindSocial is a platform designed to gather public information
              from various platforms.
            </p>
            <div className="footer-socail-icons">
              <img src="./Assets/Images/landing/footer/Spotify.png" alt="" />
              <img src="./Assets/Images/landing/footer/Instagram.png" alt="" />
              <img src="./Assets/Images/landing/footer/Youtube.png" alt="" />
            </div>
          </div>

    
          <div className="footer-section-child-2">
            <h5>Subscribe to our newsletter</h5>
            <CustomForm/>

          </div>
        </div>
        <div className="terms-conditions">
          <p style={{ cursor: "pointer" }} onClick={() => setModalOpen(true)}>
            Terms & conditions
          </p>
          <p>All rights reserved © FindSocial - 2023</p>
        </div>
      </div>
      <Modal
        show={modalOpen}
        onHide={handleClose}
        className="modal-component modal-terms-conditions"
      >
        <Modal.Header closeButton></Modal.Header>
        <TermsAndConditions />
      </Modal>
    </>
  );
}
