import React from "react";
import { Drawer } from "antd";
export default function DrawerDialog({
  Component,
  isShow,
  handleClose,
  navigation,
  userData,
  setApiCall,
  apiCall,
  menu,
  setChangeModalIsShow,
  loginWithRedirect,
}) {
  return (
    <Drawer
      open={isShow}
      placement="left"
      title={menu ? "Menu" : "User Profile"}
      onClose={handleClose}
    >
      <Component
        navigation={navigation}
        loginWithRedirect={loginWithRedirect}
        userData={userData}
        apiCall={apiCall}
        setApiCall={setApiCall}
        setChangeModalIsShow={setChangeModalIsShow}
      />
    </Drawer>
  );
}
