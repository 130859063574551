import React, { useState } from "react";

function AccountFollower({
  isToggled,
  setIsToggled,
  platform,
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-filter">
      <div
        className="filter-menu-label"
        onClick={toggleContent}
        style={{ cursor: "pointer" }}
      >
        <div>Follower range</div>
        <div>
          {isOpen ? (
            <svg
              width="10"
              height="2"
              viewBox="0 0 10 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                fill="#262626"
              />
            </svg>
          ) : (
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                fill="#262626"
              />
            </svg>
          )}
        </div>
      </div>
      {isOpen && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                gap: 27,
                flexDirection: "column",
                marginTop: 16,
              }}
            >
              {platform === "Spotify playlists" ? (
                <p className="heading">Number of likes</p>
              ) : platform === "YouTube" ? (
                <p className="heading">Number of subscribers</p>
              ) : (
                <p className="heading">Number of followers</p>
              )}
            </div>
            <div style={{ display: "flex", gap: 17 }} className="">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  color: "#878787",
                  fontSize: "14px",
                }}
              >
                <label>Min</label>

                <input
                  type="number"
                  disabled={disabledButton}
                  name=""
                  value={filterObj?.min_followers}
                  id=""
                  onChange={(ev) =>
                    setFilterObj({
                      ...filterObj,
                      min_followers: ev.target.value,
                    })
                  }
                  placeholder="00"
                  className="filter-menu-input"
                />
              </div>
              {platform === "YouTube" ||
              platform === "Spotify playlists" ||
              platform === "SoundCloud" ||
              platform === "Tiktok" ||
              platform === "Spotify artists" ||
              platform === "Instagram" ||
              platform === "Twitter" ? null : (
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      color: "#878787",
                      fontSize: "14px",
                    }}
                  >
                    <label>Min</label>

                    <input
                      disabled={disabledButton}
                      type="number"
                      name=""
                      id=""
                      placeholder="00"
                      className="filter-menu-input"
                      value={filterObj?.min_following}
                      onChange={(ev) =>
                        setFilterObj({
                          ...filterObj,
                          min_following: ev.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AccountFollower;
