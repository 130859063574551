import React, { useState } from "react";
export default function InputComponent(props) {
  const [visibility, setvisibility] = useState(true);
  const handleVisibility = () => {
    setvisibility(visibility ? false : true);
  };

  function IconRender() {
    if (props?.type == "email") {
      return (
        <img width="15" height="15" className="icon" src={props?.icon} alt="" />
      );
    } else if (props?.type == "password") {
      if (visibility == true) {
        return (
          <img
            width="15"
            height="15"
            className="icon"
            src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/external-eye-devices-flatart-icons-outline-flatarticons.png"
            alt=""
            onClick={handleVisibility}
          />
        );
      } else {
        return (
          <img
            width="15"
            height="15"
            className="icon"
            src={props?.icon}
            alt=""
            onClick={handleVisibility}
          />
        );
      }
    }
  }

  return (
    <>
      <div style={{ fontWeight: "600" }}>{props?.heading}</div>
      <div className="input-container">
        <input
          id="input-filed"
          className={`input-main ${props?.classinput}`}
          style={props?.style}
          type={visibility ? props?.type : ""}
          value={props?.value}
          placeholder={props?.placeholder}
          onChange={props?.onChange}
          disabled={props?.disabled}
          required={props?.required}
          maxLength={props?.max}
        />
        <IconRender />
      </div>
    </>
  );
}
