import React from "react";
import { toast } from "react-toastify";
import ButtonComponent from "../Components/buttonComponent";
import ImageComponent from "../Components/imageComponent";
import EditModal from "../Components/EditModal";
import { Tooltip } from "antd";
export default function ProfileComponent({
  navigation,
  userData,
  setApiCall,
  apiCall,
  setChangeModalIsShow,
  changePasswordFn,
}) {
  // console.log(userData);
  return (
    <div className="ProfileComponent">
      <div className="user-profile">
        {/* <ImageComponent
          src="/Assets/Images/abstractbackground.png"
          className="profile-bg"
        /> */}
        <div className="profile-img-parent">
          <ImageComponent
            // src="/Assets/Images/profile.png"
            src={userData?.picture}
            className="profile-img"
          />
        </div>
        <div className="name-email">
          <div className="profile-name">
            {userData?.user_id?.split("|")[0] === "auth0"
              ? userData?.username
              : userData?.name}
          </div>
          <div className="profile-email ">
            <span>{userData?.email ? userData?.email : userData?.name}</span>
            {/* <EditModal/> */}
            {/* <img src="./Assets/Images/edit.png" alt="editIcon" width={30} /> */}
          </div>
          <div className="profile-email ">
            <span>password: ********</span>
            {userData?.identities?.length &&
            userData?.identities[0]?.isSocial ? null : (
              <>
                <Tooltip
                  rootClassName="tooltip-credit-details"
                  // className="tooltip-credit-details"
                  placement="top"
                  title={`Request a password reset link`}
                  arrow={true}
                >
                  <img
                    className="profile-email-img"
                    style={{ cursor: "pointer" }}
                    src="./Assets/Images/edit.png"
                    alt="editIcon"
                    width={30}
                    onClick={changePasswordFn}
                  />
                  <img
                    className="profile-email-img-res"
                    style={{ cursor: "pointer" }}
                    src="./Assets/Images/components/edit.png"
                    alt="editIcon"
                    width={30}
                    onClick={changePasswordFn}
                  />
                </Tooltip>
              </>
            )}
            {/* <EditModal/> */}
            {/* <img src="./Assets/Images/edit.png" alt="editIcon" width={30} /> */}
          </div>
        </div>
      </div>
      {/* <div className="border-Line"></div> */}
      {/* <div className="profile-parent">
        <div className="profile-info-parent">
          <div className="info-Child">
            <h4>User Name:</h4>
            <span>
              {userData?.user_id?.split("|")[0] === "auth0"
                ? userData?.username
                : userData?.name}
            </span>
          </div>
          <div className="edit-img-parent">
            <ImageComponent
              src="/Assets/Images/editGreen.png"
              className="image-profile"
            />
          </div>
        </div>
        <div className="profile-info-parent">
          <div className="info-Child">
            <h4>
              {userData?.email
                ? "Email:"
                : `${userData?.user_id?.split("|")[0]} Name:`}
            </h4>
            <span>{userData?.email ? userData?.email : userData?.name}</span>
          </div>
          <div className="edit-img-parent">
            <ImageComponent
              src="/Assets/Images/editGreen.png"
              className="image-profile"
            />
          </div>
        </div>
        <div className="profile-info-parent">
          <div className="info-Child">
            <h4>Password:</h4>
            <span>*********</span>
          </div>
          {userData?.identities?.length
            ? !userData?.identities[0]?.isSocial && (
                <div
                  className="edit-img-parent"
                  style={{ cursor: "pointer" }}
                  onClick={setChangeModalIsShow}
                >
                  <ImageComponent
                    src="/Assets/Images/editGreen.png"
                    className="image-profile"
                  />
                </div>
              )
            : null}
        </div>
      </div> */}
      {/* <div className="border-Line"></div> */}
      {/* <div className="profile-parent">
        <ButtonComponent
          onClick={() => {
            // webAuth.logout({
            //   returnTo: window.location.origin,
            //   clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            // });
            localStorage.removeItem("accessToken");
            localStorage.removeItem("stripe_customer_id");
            localStorage.removeItem("auth-Id");
            toast.success("Logout successfully");
            setApiCall(!apiCall);
            navigation("/home");
          }}
          src={"/Assets/Images/Logout.png"}
          text={"Logout"}
          btnclass="btn-background"
        />
      </div> */}
    </div>
  );
}
