import axios from "axios";
import { toast } from "react-toastify";
import { w3cwebsocket } from "websocket";
import { useDeepSearch } from "../../store/useDeepSearch";
export function getPreviousData(prevUrl, setTableData, setPending) {
  setPending(true);
  axios
    .post(`${process.env.REACT_APP_API_URL}${prevUrl}`)
    .then((response) => {
      setTableData(response.data);
    })
    .catch((error) => console.log(error))
    .finally(() => setPending(false));
}

export async function connectToWebsocket(
  websocketUrl,
  prevClient,
  setClient,
  setTableData,
  setPending,
  search,
  result_type = null,
  userData,
  setUserData,
  deepSearch
) {
  setPending(true);
  prevClient?.close();
  const client = w3cwebsocket(websocketUrl);
  let count = "";
  count = userData?.user_metadata?.remainingCredits;
  client.onopen = () => {
    setClient(client);
    client.send(
      JSON.stringify({
        user: userData?.username,
        search,
        result_type,
        deep_search: deepSearch,
      })
    );
  };
  client.onmessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    setTableData(dataFromServer);
    setPending(false);
    showCredits(Number(count), setUserData);
  };
  client.onclose = () => {
    setPending(false);
  };
  client.onerror = (e) => {
    setPending(false);
  };
}

export function disconnectFromWebsocket(
  client,
  setDisabledButton,
  setSearch,
  setFilterText,
  disabled
) {
  client?.close();
  setDisabledButton(false);
  setFilterText("");
  setSearch("");
  if (!disabled) {
    document.getElementById("form-Submit-Search").reset();
  }
}

export const showCredits = (
  remainingCredits,
  setUserData,
  client,
  userData,
  apiToken
) => {
  // if (userData?.user_metadata?.token) {
  //   var options = {
  //     method: "PATCH",
  //     url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${JSON.parse(
  //       localStorage.getItem("auth-Id")
  //     )}`,
  //     headers: {
  //       authorization: `Bearer ${userData?.user_metadata?.token}`,
  //       "content-type": "application/json",
  //     },
  //     data: {
  //       user_metadata: {
  //         remainingCredits: remainingCredits,
  //       },
  //     },
  //   };
  //   axios
  //     .request(options)
  //     .then(function (response) {
  //       setUserData(response.data);
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //       if (error?.response?.data?.error === "Unauthorized") {
  //         var options = {
  //           method: "post",
  //           url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
  //           headers: { "content-type": "application/json" },
  //           data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
  //         };
  //         axios(options)
  //           .then((res) => {
  //             var options = {
  //               method: "PATCH",
  //               url: `${
  //                 process.env.REACT_APP_AUTH0_API_DOMAIN
  //               }/api/v2/users/${JSON.parse(localStorage.getItem("auth-Id"))}`,
  //               headers: {
  //                 authorization: `Bearer ${res.data?.access_token}`,
  //                 "content-type": "application/json",
  //               },
  //               data: {
  //                 user_metadata: {
  //                   remainingCredits: remainingCredits,
  //                   token: res.data?.access_token,
  //                 },
  //               },
  //             };
  //             axios
  //               .request(options)
  //               .then(function (response) {
  //                 setUserData(response.data);
  //               })
  //               .catch(function (error) {
  //                 console.error(error);
  //               });
  //           })
  //           .catch(function (error) {
  //             disconnectFromWebsocket(client);
  //             toast.error("Error occured");
  //             console.log(error);
  //           });
  //       }
  //     });
  // } else {
  //   var options = {
  //     method: "post",
  //     url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
  //     headers: { "content-type": "application/json" },
  //     data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
  //   };
  //   axios(options)
  //     .then((res) => {
  //       var options = {
  //         method: "PATCH",
  //         url: `${
  //           process.env.REACT_APP_AUTH0_API_DOMAIN
  //         }/api/v2/users/${JSON.parse(localStorage.getItem("auth-Id"))}`,
  //         headers: {
  //           authorization: `Bearer ${res.data?.access_token}`,
  //           "content-type": "application/json",
  //         },
  //         data: {
  //           user_metadata: {
  //             remainingCredits: remainingCredits,
  //             token: res.data?.access_token,
  //           },
  //         },
  //       };
  //       axios
  //         .request(options)
  //         .then(function (response) {
  //           setUserData(response.data);
  //         })
  //         .catch(function (error) {
  //           console.error(error);
  //         });
  //     })
  //     .catch(function (error) {
  //       disconnectFromWebsocket(client);
  //       toast.error("Error occured");
  //       console.log(error);
  //     });
  // }
  var options = {
    method: "PATCH",
    url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
    headers: {
      authorization: `Bearer ${apiToken}`,
      "content-type": "application/json",
    },
    data: {
      user_metadata: {
        remainingCredits: remainingCredits,
        token: apiToken,
      },
    },
  };
  axios
    .request(options)
    .then(function (response) {
      console.log(remainingCredits);
      setUserData(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};
