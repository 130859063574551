import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./input.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "antd/dist/reset.css";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      // responseType: "token",
      // responseMode: "fragment",
      redirect_uri: process.env.REACT_APP_DEPLOY_DOMAIN + "/authentication",
      // audience: process.env.REACT_APP_AUTH_DOMAIN,
      scope:
        "read:current_user read:current_user_app_metadata update:current_user_metadata",
    }}
  >
    <BrowserRouter>
      <ToastContainer />
      <App />
    </BrowserRouter>
  </Auth0Provider>
);
reportWebVitals();
