import React from "react";
import { Modal } from "react-bootstrap";
import ButtonComponent from "./buttonComponent";
import InputComponent from "./inputComponent";
export default function ChangePasswordModal({
  isShow,
  handleChange,
  setPassword,
  setConfirmPassword,
  Password,
  confirmPassword,
  changePasswordFn
}) {
  return (
    <Modal show={isShow} onHide={handleChange} className="subscriptionModal">
      <Modal.Body>
        <div>
          <h3>Change Password</h3>
          <form style={{ width: "100%" }} onSubmit={changePasswordFn}>
            <InputComponent
              value={Password}
              classinput={"inputBg"}
              placeholder={"password"}
              type={"password"}
              required={true}
              onChange={(ev) => setPassword(ev.target.value)}
            />
            <InputComponent
              value={confirmPassword}
              classinput={"inputBg"}
              placeholder={"password"}
              type={"password"}
              required={true}
              onChange={(ev) => setConfirmPassword(ev.target.value)}
            />
            <ButtonComponent text={"submit"} btnclass="btn-background mb-2" />
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
