import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderUI from "./HeaderUI";
import ModalUI from "./ModalUI";
import { toast } from "react-toastify";
import FooterComponent from "../Components/footerComponent";
import PlatformsComponent from "../Components/platformsComponent";
import InprogressComponent from "../Components/inprogressComponent";
import { useDeepSearch } from "../store/useDeepSearch";
function HomeUI({
  userData,
  setApiCall,
  setUserData,
  apiCall,
  isLoading,
  isAuthenticated,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigation = useNavigate();
  const { setDeepSearchFalse } = useDeepSearch();

  useEffect(() => {
    setDeepSearchFalse();
  }, []);
  useEffect(() => {
    // if (
    //   localStorage.getItem("auth-Id") &&
    //   // !userData?.email_verified &&
    //   userData?.user_id
    // ) {
    //   navigation("/verify-account");
    // }
    if (!isAuthenticated && !isLoading) {
      navigation("/");
    } else if (userData?.user_id) {
      if (!userData?.user_metadata?.subscriptionPlan) {
        navigation("/subscription-plan");
      }
    }
  }, [userData, isLoading, isAuthenticated]);
  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      {/* <FooterComponent /> */}
      <div className="home-ui-child-bg">
        <div className="bg-child-platform">
          <h3>Select your platform</h3>
          <p>
            Get contacts fast. Search contacts on any platform including Tiktok,
            Instagram, Spotify, Spotify playlists,
            <br /> SoundCloud, YouTube and Twitter.
            {/* egestas enim diam laoreet justo. Sed suspendisse pulvinar mi sed. */}
          </p>
        </div>
        <div className="socail-icons-box-parent">
          <img src={"./Assets/Images/landing/socail/instragram.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/soundclould.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/shopify.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/tiktok.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/youtube.png"} alt="" />
          <div className="twitter-social-icom-list hidden">
            <img src={"./Assets/Images/landing/socail/twitter.webp"} alt="" />
          </div>
        </div>
      </div>
      <PlatformsComponent navigation={navigation} handleShow={handleShow} />
      <ModalUI
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        navigation={navigation}
      />
      {/* <InprogressComponent headerText={"processing"} /> */}
      {/* <InprogressComponent headerText={"Processing"} /> */}
    </React.Fragment>
  );
}

export default HomeUI;

// <div className="container">
//   <div className="row">
//     <div className="col-lg-12 col-md-12 col-sm-12 container-fuild-margin">
//       <div className="platform">
//         <h1 className="Select-Platform">Select Platform</h1>
//         <p className="p-text">
//           Get contacts fast. Search contacts on any platform including
//           Tiktok, Instagram, Spotify, Spotify playlists,
//           <br />
//           Soundcloud and .
//         </p>
//         <div className="div-hr">
//           <hr className="hr" />
//         </div>
//         <div className="selectpt">
//           <Link
//             className="select"
//             onClick={() => {
//               if (!userData?.user_id) {
//                 toast.error("You are not login");
//               }
//             }}
//             to={userData?.user_id && "/sound-cloud-dashboard"}
//           >
//             <div className="select-margin-items soundcloud">
//               <img
//                 src="/Assets/Images/socailMedia/soundcloud.png"
//                 className="rounded-circle s1"
//                 alt=""
//                 srcSet=""
//                 width="100px"
//                 height="100px"
//               />
//               <h5 className="h5-div-heading">
//                 {/* <br /> */}
//                 Sound Cloud
//               </h5>
//               <p className="hovering">
//                 Streamline the process of finding and accessing artist
//                 contact information from Soundcloud, making it very easy
//                 and fast to connect with musicians, bands, producers,
//                 vocalists and other artists.
//               </p>
//             </div>
//           </Link>
//           <Link
//             className="select"
//             onClick={() => {
//               if (!userData?.user_id) {
//                 toast.error("You are not login");
//               }
//             }}
//             to={userData?.user_id && "/tiktok-dashboard"}
//           >
//             <div className="select-margin-items tiktok">
//               <img
//                 src="/Assets/Images/socailMedia/tiktok.png"
//                 className="rounded-circle s1"
//                 alt=""
//                 width="100px"
//                 height="100px"
//               />
//               <h5 className="h5-div-heading">
//                 {/* <br /> */}
//                 TikTok
//               </h5>
//               <p className="hovering">
//                 The go-to tool for quickly and easily discovering
//                 influencer contact information on TikTok, empowering
//                 artists, businesses and brands to connect with popular
//                 content creators for collaborations, sponsorships, and
//                 partnerships with just a few clicks.
//               </p>
//             </div>
//           </Link>
//           <Link
//             className="select"
//             onClick={() => {
//               if (!userData?.user_id) {
//                 toast.error("You are not login");
//               }
//             }}
//             to={userData?.user_id && "/youtube-dashboard"}
//           >
//             <div className="select-margin-items youtube">
//               <img
//                 src="/Assets/Images/socailMedia/youtube.png"
//                 className="rounded-circle s1"
//                 alt=""
//                 width="100px"
//                 height="100px"
//               />
//               <h5 className="h5-div-heading">
//                 {/* <br /> */}
//                 Youtube
//               </h5>
//               <p className="hovering">
//                 The ultimate tool for effortlessly discovering and
//                 accessing channel information and contact details from
//                 YouTube, empowering artists, creators and businesses to
//                 connect, collaborate, and grow their YouTube networks with
//                 ease.
//               </p>
//             </div>
//           </Link>
//           <ModalUI userData={userData} />
//           <Link
//             className="select"
//             onClick={() => {
//               if (!userData?.user_id) {
//                 toast.error("You are not login");
//               }
//             }}
//             to={userData?.user_id && "/instagram-dashboard"}
//           >
//             <div className="select-margin-items instagram">
//               <img
//                 src="/Assets/Images/socailMedia/instragram.png"
//                 className="rounded-circle s1"
//                 alt=""
//                 width="100px"
//                 height="100px"
//               />
//               <h5 className="h5-div-heading">
//                 {/* <br /> */}
//                 Instagram
//               </h5>
//               <p className="hovering">
//                 ​Quickly discover and access influencer contact
//                 information on Instagram, making it simple for businesses
//                 and brands to connect with popular Instagram influencers
//                 for collaborations, promotions, and partnerships, all in
//                 one convenient platform.
//               </p>
//             </div>
//           </Link>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
