import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputComponent from "./inputComponent";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

export default function DownloadModal({
  open,
  handleClose,
  data,
  csvFile,
  disabledButton,
  rows_data_for_export,
  columns_data_for_export,
  filterTextSelect,
  download_pdf,
  descriptions,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: "400px", textAlign: "center" } }}
    >
      <div className="cross-button" onClick={handleClose}>
        <CloseIcon />
      </div>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
      >
        {"Download file"}
      </DialogTitle>
      <DialogContent>{descriptions}</DialogContent>
      <DialogActions>
        {" "}
        {csvFile ? (
          <CSVLink
            onClick={() => {
              if (!disabledButton) {
                handleClose();
              }
              return !disabledButton;
            }}
            style={
              disabledButton
                ? {
                    background:
                      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)",
                    opacity: 0.7,
                    textDecoration: "none",
                    margin: "10px",
                    cursor: "unset",
                    width: "100%",
                  }
                : {
                    textDecoration: "none",
                    width: "100%",
                    margin: "10px",
                    background:
                      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)",
                  }
            }
            className="btn-background download-btn flaot-import-button flaot-border-radius float-button"
            data={rows_data_for_export}
            // new Date().toDateString()
            headers={columns_data_for_export}
            filename={`${data.name}-${
              filterTextSelect === "" || filterTextSelect === "filter"
                ? "select-all"
                : filterTextSelect
            }-${new Date()}.csv`}
          >
            Download
          </CSVLink>
        ) : (
          <ButtonComponent
            style={{
              height: "38px",
              width: "100%",
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "10px",
              width: "100%",
              opacity: disabledButton ? 0.7 : 1,
            }}
            text={"Download"}
            btnclass="btn-background download-btn"
            onClick={download_pdf}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
