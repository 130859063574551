import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LogoutModal from "../Pages/logoutModal";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MenuComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [logoutModal, setLogoutModal] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = () => {
    setLogoutModal(true);
  };
  const handleCloseLogout = () => {
    setLogoutModal(false);
  };

  return (
    <>
      <div className={props?.className}>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          // variant="contained"
          style={{ textTransform: "capitalize" }}
          disableElevation
          onClick={handleClick}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          sx={{
            background:
              "linear-gradient(90deg, rgba(1, 195, 141, 0.3) 12.66%, rgba(119, 40, 181, 0.3) 100%)",
            color: "black",
            fontWeight: "bold",
          }}
        >
          <img
            src={props?.picture}
            width={35}
            height={35}
            style={{ borderRadius: "8px", marginRight: "6px" }}
            alt=""
            srcset=""
          />
          {props.name}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              console.log("clicked");
              props?.navigation("/home");
            }}
            disableRipple
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              aligItems: "center",
            }}
          >
            {/* <svg
            stroke="currentColor"
            fill="none"
            stroke-width={2}
            viewBox="0 0 24 24"
            stroke-linecap="round"
            stroke-linejoin="round"
            height={22}
            width={22}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
          </svg> */}
            <div className="profile-text">Dashboard</div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              console.log("clicked");
              props?.navigation("/account-overview");
            }}
            disableRipple
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              aligItems: "center",
            }}
          >
            {/* <img src="/Assets/Images/profile1.png" /> */}
            <div className="profile-text">Profile</div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpen();
            }}
            disableRipple
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              aligItems: "center",
            }}
          >
            {/* <img src="/Assets/Images/logout1.png" /> */}
            <div className="profile-text">Logout</div>
          </MenuItem>
        </StyledMenu>
      </div>
      <LogoutModal
        navigation={props?.navigation}
        isShow={logoutModal}
        setUserData={props?.setUserData}
        handleclose={handleCloseLogout}
      />
    </>
  );
}
