import { Tag, Tooltip } from "antd";
import UpgradeModal from "../../Components/UpgradeModal";

const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);

const Instagram = (
  userData,
  setUserData,
  disabledButton,
  upgradeModal,
  setupgradeModal,
  loader,
  setLoader,
  loaderMsgId,
  setLoaderMsgId,
  handleRowClicked
) => {
  let obj = {
    name: "Instagram",
    url: "instagram-dashboard",
    previousDataUrl: (user) => `/instagram-results?user=${user}`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/instagram-search-socket`,
    websocketUrlListSearch: `${process.env.REACT_APP_WEBSOCKET}/instagram-links-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/instagram-search-test-socket`,
    websocketTestListUrl: `${process.env.REACT_APP_API_URL}/instagram-list-test-socket`,

    columns: [
      {
        name: "URL",
        selector: (row) => row.url,
        sortable: disabledButton ? false : true,
        cell: (row) => (
          <Tooltip placement="topLeft" title={`${row?.url}`} arrow={true}>
            <a
              href={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </a>
          </Tooltip>
        ),
        value: "url",
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "",
        selector: (row) => row.deep_search,
        sortable: disabledButton ? true : false,
        cell: (row) =>
          row?.deep_search ? (
            <Tag
              color={"purple"}
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClicked(row)}
            >
              Deep Search
            </Tag>
          ) : null,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
      },
      {
        name: "Profile's name",
        selector: (row) => row.artist_name,
        cell: (row) => <CustomTitle row={row.artist_name} />,
        value: "artist_name",
        sortable: disabledButton ? false : true,
      },
      {
        name: "Email",
        selector: (row) => row?.email,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row?.email?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row?.email?.length
                ? row?.email?.map((url, index) => (
                    <span>
                      {url}
                      {row?.email?.length - 1 === index ? "" : ","}{" "}
                    </span>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "email",
        sortable: disabledButton ? false : true,
      },
      {
        name: "Followers",
        selector: (row) => row.followers,
        cell: (row) => <CustomTitle row={row.followers} />,
        value: "followers",
        sortable: disabledButton ? false : true,
      },
      {
        name: "Link",
        selector: (row) => row.link,
        cell: (row) => <CustomTitle row={row.link} />,
        value: "link",
        sortable: disabledButton ? false : true,
      },
      {
        name: "Send message",
        selector: (row) => (
          <UpgradeModal
            cookieName={"instagram_rur"}
            cookieName2={"instagram_sessionid"}
            disabledButton={disabledButton}
            name={"Instagram"}
            routesname={"instagram"}
            src="/Assets/Images/components/Sendmsg.png"
            socailIcon={"instragram"}
            uid={row?.username}
            premium={
              userData?.user_metadata?.subscriptionPlan === "Pro" ? true : false
            }
            setLoaderMsgId={setLoaderMsgId}
            loaderMsgId={loaderMsgId}
            userData={userData}
            setUserData={setUserData}
            chatAPItUrl={`${process.env.REACT_APP_API_URL}/instagram-send-message`}
            chatSocketUrl={`${process.env.REACT_APP_WEBSOCKET}/instagram-send-message`}
            upgradeModal={upgradeModal}
            setupgradeModal={setupgradeModal}
            loader={loader}
            setLoader={setLoader}
          />
        ),
        style: {
          width: "50px", // override the row height
        },
        value: "Send message",
        sortable: false,
      },
    ],
  };
  return obj;
};

export { Instagram };
