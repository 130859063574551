import React, { useState, useEffect, useCallback, useMemo } from "react";
import BreadCrumb from "./BreadCrumb";
import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { w3cwebsocket } from "websocket";
import { getPreviousData, showCredits } from "../Data/Api/dashboard";
import { createTheme } from "@mui/material/styles";
import { disconnectFromWebsocket } from "../Data/Api/dashboard";
import HeaderUI from "./HeaderUI";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SelectComponent } from "../Components/Select";
import { Processing } from "../Components/Processing";
import DownloadModal from "../Components/downloadModal";
import ButtonComponent from "../Components/buttonComponent";

import UpdateModal from "../Components/updateModal";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useDeepSearch } from "../store/useDeepSearch";
import { Modal } from "antd";

export default function Dashboard({
  isExpire,
  data: data2,
  userData,
  setUserData,
  setApiCall,
  apiCall,
  userInfo,

  apiToken,
  disabledButton,
  setDisabledButton,
  disableSocket,
}) {
  const { deepSearch, setShowModal, selectedRow, showModal } = useDeepSearch();
  console.log(selectedRow, "sasalas");

  const [data, setData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [downloadPDFModal, setDownloadPDFModal] = useState(false);
  const [downloadCSVModal, setDownloadCSVModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [searchScrappedData, setSearchScrappedData] = useState("");
  const [filterObj, setFilterObj] = useState({
    email: false,
    instagram: false,
    tiktok: false,
  });
  const [filter, setFilter] = useState({
    min_followers: 0,
    min_following: 0,
  });
  const [filterTextclick, setFilterTextclick] = useState("");
  const [filterTextSelect, setFilterTextSelect] = useState("All");
  const [customSorttext, setcustomSorttext] = useState("asc");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [disableScrapping, setDisableScrapping] = useState(false);
  const [disableScrappingImport, setDisableScrappingImport] = useState(false);
  const [filteredOldData, setFilteredOldData] = useState([]);
  const [customSortcount, setcustomSortcount] = useState(1);
  const [searchApi, setSearchApi] = useState(false);
  const [importSearch, setImportSearch] = useState(false);
  const [search, setSearch] = useState(null);
  const [client, setClient] = useState(null);
  const [files, setFiles] = useState("");
  const [dataToggle, setDataToggle] = useState(false);
  const [columns_data_for_export, setColumns_data_for_export] = useState(false);
  const [rows_data_for_export, setRows_data_for_export] = useState(false);
  const navigate = useNavigate();
  const [upgradeModal, setupgradeModal] = useState(false);
  const [upgradeAfter, setupgradeAfter] = useState("");
  const { isAuthenticated, isLoading } = useAuth0();
  var url = new URL(window.location);
  let open = url.searchParams.get("open");

  useEffect(() => {
    console.log("ldfjkljlkjfkljkfjkl jkjdk jljkljkl");
    if (!disabledButton) {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
        if (getData) {
          if (userInfo) {
            getPreviousData(
              getData?.previousDataUrl(userInfo?.user_id),
              setTableData,
              setPending
            );
          }
        }
      } else {
        setData(data2);
        if (data2) {
          if (userInfo) {
            getPreviousData(
              data2?.previousDataUrl(userInfo?.user_id),
              setTableData,
              setPending
            );
          }
        }
      }
    } else {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    }
  }, [dataToggle, userInfo, disabledButton]);

  useEffect(() => {
    if (disableSocket) {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    } else {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    }
  }, [disableSocket]);
  const scrapperCheckFn = () => {
    if (!disabledButton) {
      console.log("runnnnninh");

      axios
        .get(data.websocketTestUrl)
        .then((response) => {
          setDisableScrapping(response?.data?.maintenance);
          if (data.name === "Instagram") {
            setDisableScrappingImport(!response?.data?.Success);
          }
        })
        .catch((error) => {
          setDisableScrapping(error.response?.data?.maintenance);

          if (data.name === "Instagram") {
            setDisableScrappingImport(!error?.response?.data?.Success);
          }
        });
    }
    if (data.name === "Instagram") {
      axios
        .get(data.websocketTestListUrl)
        .then((response) => {})
        .catch((error) => {});
    }
  };
  useEffect(() => {
    if (data) {
      scrapperCheckFn();
    }
  }, [data]);
  const theme = createTheme();
  const handleClose = () => {
    setupgradeModal(false);
  };
  const isOnlySpaces = /^\s*$/;
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated]);
  useEffect(() => {
    if (open === "PDF") {
      setDownloadPDFModal(true);
    } else if (open === "CSV") {
      setDownloadCSVModal(true);
    }
  }, [open]);

  const csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    let arr = [];
    csvRows.map((i) => {
      const values = i.split(",");
      let str = values[0];
      if (typeof str === "string") {
        // let value = str.split('"')[1];
        // if (typeof value === "string") {
        arr.push(str);
        // } else {
        //   arr.push(JSON.parse(value));
        // }
      } else {
        arr.push(JSON.stringify(str));
      }
      // const obj = csvHeader.reduce((object, header, index) => {
      //   object[header] = JSON.parse(values[index]);
      //   return object;
      // }, {});
      // return obj;
    });
    let data = arr.join(",");
    setFilterText(data);
    setImportSearch(true);
    // onButtonClicked(data);
  };

  useEffect(() => {
    if (files !== "") {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;

        csvFileToArray(csvOutput);
      };
      fileReader.readAsText(files);
    }
  }, [files]);

  const ids = tableData?.map((o) => {
    if (o == null) {
      return;
    } else {
      return o?.search;
    }
  });

  const PreviousListText = useMemo(
    () =>
      ids?.length
        ? ids[0]
          ? tableData?.filter((search, index) =>
              search !== null
                ? !ids?.includes(search?.search, index + 1)
                : false
            )
          : tableData
        : null,
    [tableData, ids]
  );

  const columns = data?.columns;
  function connectToWebsocket(
    websocketUrl,
    websocketUrlListSearch,
    prevClient,
    setClient,
    setTableData,
    setPending,
    search,
    result_type,
    userData,
    setUserData,
    filterObj,
    filtering,
    setFilters,
    apiToken,
    importSearch,
    setImportSearch,
    setSearch,
    setDisabledButton
  ) {
    let count = userData?.user_metadata?.remainingCredits;
    let emailcheck = document.getElementById("email");
    let instagramcheck = document.getElementById("instagram");
    if (data.name === "YouTube") {
      let tiktokcheck = document.getElementById("tiktok");
      tiktokcheck.checked = false;
      setFilterObj({
        email: false,
        instagram: false,
        tiktok: false,
      });
    }
    if (
      data.name !== "Instagram" ||
      data.name !== "Twitter" ||
      data.name !== "SoundCloud"
    ) {
      if (instagramcheck?.checked) {
        instagramcheck.checked = false;
        setFilterObj({
          email: false,
          instagram: false,
          tiktok: false,
        });
      }
    }
    if (data.name !== "Spotify artists") {
      if (emailcheck.checked) {
        emailcheck.checked = false;
        setFilterObj({
          email: false,
          instagram: false,
          tiktok: false,
        });
      }
      // if (data.name !== "Instagram") {
    }

    setPending(true);
    prevClient?.close();
    const client = w3cwebsocket(
      importSearch ? websocketUrlListSearch : websocketUrl
    );

    client.onopen = () => {
      setClient(client);
      let filters =
        data.name === "Instagram"
          ? {
              min_followers: Number(filtering.min_followers),
            }
          : data.name === "Spotify playlists"
          ? {
              likes: Number(filtering.min_followers),
            }
          : data.name === "YouTube"
          ? {
              subscribers: Number(filtering.min_followers),
            }
          : data.name === "SoundCloud" ||
            data.name === "Tiktok" ||
            data.name === "Spotify artists" ||
            data.name === "Twitter"
          ? {
              followers: Number(filtering.min_followers),
            }
          : {
              followers: Number(filtering.min_followers),
              following: Number(filtering.min_following),
            };
      if (result_type === null) {
        console.log(deepSearch);

        client.send(
          JSON.stringify({
            user: userData?.user_id,
            search,
            filters: { ...filters },
            deep_search: deepSearch,
          })
        );
      } else {
        console.log(deepSearch);

        client.send(
          JSON.stringify({
            user: userData?.user_id,
            result_type: result_type,
            search,
            filters: { ...filters },
            deep_search: deepSearch,
          })
        );
      }
    };

    let arr = [];

    client.onmessage = (message) => {
      let credits = 0;
      if (count >= credits) {
        const dataFromServer = JSON.parse(message.data);
        arr = dataFromServer;
        let scrapData = dataFromServer[dataFromServer?.length - 1];
        if (scrapData === null || !scrapData) {
          console.log("Nulsdjhsdjk");
        } else {
          if (typeof dataFromServer[dataFromServer?.length - 1] === "object") {
            let email = dataFromServer?.length
              ? dataFromServer[dataFromServer?.length - 1]?.data
              : [];
            if (data.name === "Instagram" || data.name === "Tiktok") {
              credits = credits + 1;
            }
            if (data.name === "YouTube") {
              if (email?.tiktok?.length) {
                credits = credits + 1;
              }
            }
            if (email?.email?.length) {
              credits = credits + 1;
            }
            if (data.name !== "Instagram") {
              if (email?.instagram?.length) {
                credits = credits + 1;
              }
            }
            setTableData(dataFromServer);
            setPending(false);
            console.log(credits, "creditsBeforeApi");
            if (credits !== 0) {
              count = count - credits;
              showCredits(count, setUserData, client, userData, apiToken);
            }
          }
        }
      } else {
        toast.error("You don't have enough credits");
        setPending(false);
        setTableData([]);
        setFilters({
          min_followers: 0,
          min_following: 0,
        });
        setImportSearch(false);
        disconnectFromWebsocket(
          client,
          setDisabledButton,
          setSearch,
          setFilterText
        );
      }
    };

    client.onclose = (e) => {
      console.log(e, "e");
      setPending(false);
      if (!arr?.length) {
      }
      setImportSearch(false);
      setFilter({
        min_followers: 0,
        min_following: 0,
      });
      disconnectFromWebsocket(
        client,
        setDisabledButton,
        setSearch,
        setFilterText
      );
    };
    client.onerror = (e) => {
      toast.error("Under maintenance / Upgrade in progress");
      console.log(e);
      setPending(false);
      setImportSearch(false);
      setFilter({
        min_followers: 0,
        min_following: 0,
      });
      disconnectFromWebsocket(
        client,
        setDisabledButton,
        setSearch,
        setFilterText
      );
    };
  }

  useEffect(() => {
    if (search !== null && search !== "") {
      setFilterTextSelect("");
      setDisabledButton(true);
      setFilteredData([]);
      connectToWebsocket(
        data.websocketUrl,
        data.websocketUrlListSearch,
        client,
        setClient,
        setTableData,
        setPending,
        search,
        data.resultType,
        userData,
        setUserData,
        filterObj,
        filter,
        setFilter,
        apiToken,
        importSearch,
        setImportSearch,
        setSearch,
        setDisabledButton
      );
    }
  }, [search]);
  const onButtonClicked = useCallback(
    (search, isExpire) => {
      console.log(isExpire);
      if (!isOnlySpaces.test(search)) {
        if (userData?.user_metadata?.remainingCredits < 1) {
          setupgradeModal(true);
          toast.error("You don't have enough credits");
          document.getElementById("form-Submit-Search").reset();
        } else {
          console.log("run");

          setSearch(search);
          setSearchApi(true);
        }
      }
    },
    [data, client]
  );
  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === customSorttext ? comparison * -1 : comparison;
    });
  };
  const [filterdata, setFilterData] = useState([]);

  const filteredItemsArray = [];
  const getfilteredItems = () => {
    setFilteredOldData([]);
    const filteredItemsArrayRowsData = tableData
      ?.filter((person) =>
        filterTextSelect === "" || filterTextSelect === "All"
          ? person?.search
          : person?.search === filterTextSelect
      )
      .map((item, index) => (filteredItemsArray[index] = item?.data));
    let filteredItems = filteredItemsArrayRowsData?.filter(
      (item) =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterTextclick?.toLowerCase()) !== -1
    );
    let columns_data_for_export = [
      ...(columns?.map((d) => d.name) || []), // Existing columns from `columns`
      "instagramname", // Instagram fields
      "instagramurl",
      "instagrambusiness",
      "instagramcatagory_name",
      "instagramverified",
      "instagramposts",
      "instagramfollowing",
      "instagramfollowers",
      "instagramusername",
    ];
    let change = filteredItems.map((item) => {
      if (item.deep_search && item.deep_search.instagram) {
        const instagramData = item.deep_search.instagram;

        const updatedInstagramData = Object.fromEntries(
          Object.entries(instagramData).map(([key, value]) => [
            key.startsWith("instagram")
              ? key
              : `instagram${key.charAt(0).toLowerCase() + key.slice(1)}`, // Check if key already starts with "instagram"
            value,
          ])
        );

        item.deep_search.instagram = updatedInstagramData;
      }

      return item;
    });

    let rows_data_for_export = change?.map((d1) => {
      return columns_data_for_export
        ?.map((item) => item.toLowerCase())
        ?.map((d2) => {
          console.log(d1?.deep_search?.instagram, "sasas");

          if (d1[d2] !== undefined) {
            return d1[d2];
          } else if (
            d1.deep_search?.instagram &&
            d1.deep_search.instagram[d2] !== undefined
          ) {
            return d1.deep_search.instagram[d2];
          } else {
            return "";
          }
        });
    });

    setRows_data_for_export(rows_data_for_export);
    setColumns_data_for_export(columns_data_for_export);
    setFilteredOldData(filteredItems);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  useEffect(() => {
    getfilteredItems();
  }, [tableData, filterTextSelect]);

  useEffect(() => {
    emailFilter();
  }, [
    filterObj?.email,
    filterObj?.instagram,
    filterObj?.tiktok,
    filteredOldData,
  ]);
  const emailFilter = () => {
    setFilteredData([]);
    let filter = [];
    if (filterObj?.email || filterObj?.instagram || filterObj?.tiktok) {
      if (filterObj?.instagram && filterObj?.email && filterObj?.tiktok) {
        filter = filteredOldData.filter(
          (ev) =>
            ev?.email?.length !== 0 ||
            ev?.instagram?.length !== 0 ||
            ev?.tiktok?.length !== 0
        );
      } else if (filterObj?.instagram && filterObj?.email) {
        filter = filteredOldData.filter(
          (ev) => ev?.email?.length !== 0 || ev?.instagram?.length !== 0
        );
      } else if (filterObj?.tiktok && filterObj?.email) {
        filter = filteredOldData.filter(
          (ev) => ev?.email?.length !== 0 || ev?.tiktok?.length !== 0
        );
      } else if (filterObj?.email) {
        if (data.name === "SoundCloud") {
          filter = filteredOldData.filter((ev) => ev?.email?.length !== 0);
        } else {
          filter = filteredOldData.filter((ev) => ev?.email?.length !== 0);
        }
      } else if (filterObj?.instagram) {
        filter = filteredOldData.filter((ev) => ev?.instagram?.length !== 0);
      } else if (filterObj?.tiktok) {
        filter = filteredOldData.filter((ev) => ev?.tiktok?.length !== 0);
      }
    } else {
      filter = filteredOldData;
    }

    setRows_data_for_export(rows_data_for_export);
    setColumns_data_for_export(columns_data_for_export);
    setFilteredData(filter);
    setFilteredSearchData(filter);
  };

  const onFilterOnClick = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setFilterText(e.target.value);
      setImportSearch(false);
      setFilterTextclick("");
    } else {
      setFilterText(e.target.value);
    }
  };

  const PreviousListFilterOnChange = (e) => {
    setResetPaginationToggle(!resetPaginationToggle);
    if (e === "All") {
      setFilterTextSelect(e);
    } else {
      if (e === "") {
        if (searchApi) {
          setDataToggle(!dataToggle);
          setSearchApi(false);
        }
        setFilterTextSelect(e);
      } else {
        setFilterTextSelect(e);
      }
    }
  };
  useEffect(() => {
    if (!isOnlySpaces.test(searchScrappedData)) {
      const filter = filteredSearchData.filter((item) =>
        Object.keys(item).some((key) => {
          const value = item[key];
          return (
            typeof value === "string" &&
            value
              .toLowerCase()
              .includes(searchScrappedData.trim().toLowerCase())
          );
        })
      );

      setFilteredData(filter);
    } else {
    }
  }, [searchScrappedData, filteredData]);

  const [isFilter, setIsFilter] = useState(true);
  const download_pdf = () => {
    if (!disabledButton) {
      // let str =data.name===""?"p", "mm", [1500, 1500]:""
      const doc =
        data.name === "YouTube"
          ? new jsPDF("p", "mm", [1200, 1200], {
              orientation: "landscape",
              // floatPrecision: true,
            })
          : new jsPDF("p", "mm", [1200, 1200], {
              orientation: "landscape",
              floatPrecision: true,
            });

      let obj = {};
      for (let i = 0; i < filteredOldData?.length; i++) {
        obj[i] = { cellWidth: 50 };
      }

      doc.autoTable({
        head: [columns_data_for_export],
        body: rows_data_for_export,
        columnStyles: obj,
      });

      doc.save(
        `${data.name}-${
          filterTextSelect === "" || filterTextSelect === "All"
            ? "select-all"
            : filterTextSelect
        }-${new Date()}.pdf`
      );
      setDownloadPDFModal(false);
    }
  };

  const onChangeRoute = () => {
    console.log(data.name.toLowerCase());
    navigate(
      `/subscription-plan?redirect=${data.name.toLowerCase()}-dashboard${
        upgradeAfter !== "" ? `&open=${upgradeAfter}` : ""
      }`
    );
  };

  useEffect(() => {
    return () => {
      if (client?.readyState === 1) {
        // Cleanup event listener on component unmount
        setImportSearch(false);
        disconnectFromWebsocket(
          client,
          setDisabledButton,
          setSearch,
          setFilterText,
          true
        );
      }
    };
  }, [client]);

  const customStyles = {};
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleContent = () => {
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    setIsOpen5(false);
    setIsOpen(!isOpen);
  };
  const toggleContent2 = () => {
    setIsOpen4(false);

    setIsOpen(false);
    setIsOpen3(false);
    setIsOpen5(false);
    setIsOpen2(!isOpen2);
  };
  const toggleContent3 = () => {
    setIsOpen4(false);
    setIsOpen5(false);

    setIsOpen2(false);

    setIsOpen(false);

    setIsOpen3(!isOpen3);
  };
  const toggleContent4 = () => {
    setIsOpen3(false);

    setIsOpen2(false);
    setIsOpen5(false);

    setIsOpen(false);

    setIsOpen4(!isOpen4);
  };
  const toggleContent5 = () => {
    setIsOpen3(false);

    setIsOpen2(false);

    setIsOpen(false);

    setIsOpen4(false);
    setIsOpen5(!isOpen5);
  };

  return (
    <>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div className="margin-left-right">
        <BreadCrumb page={data.name} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 container-fuild-margin">
            <h1>Dashboard</h1>
          </div>
        </div>

        <FilterComponent
          disableScrapping={disableScrapping}
          disableScrappingImport={disableScrappingImport}
          data={data}
          onFilter={onFilterOnClick}
          filterText={filterText}
          setFiles={setFiles}
          setSearch={setSearch}
          setFilterText={setFilterText}
          filterTextclick={onButtonClicked}
          isExpire={isExpire}
          client={client}
          disabledButton={disableSocket || disabledButton}
          disconnectFromWebsocket={disconnectFromWebsocket}
          setDisabledButton={setDisabledButton}
          files={files}
          setFilterObj={setFilter}
          filterObj={filter}
          platform={data.name}
          userData={userData}
          importSearch={importSearch}
          setImportSearch={setImportSearch}
        />

        <div className="row container-fuild-margin">
          <span className="input-text-check-box">
            <input
              type="text"
              placeholder="Search"
              className="mass-input"
              value={searchScrappedData}
              disabled={disabledButton}
              onChange={(ev) => {
                if (isOnlySpaces.test(ev.target.value)) {
                  setSearchScrappedData("");
                  getfilteredItems();
                } else if (ev.target.value === "") {
                  setSearchScrappedData(ev.target.value);
                  getfilteredItems();
                } else {
                  setSearchScrappedData(ev.target.value);
                }
              }}
              id="input-filed"
            />
            <div className="checkBoxes">
              {data.name === "Spotify artists" ? null : (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    type="checkbox"
                    id="email"
                    className="checkBox"
                    name="remember"
                    disabled={disabledButton}
                    value="1"
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          email: !filterObj?.email,
                        });
                      }
                    }}
                  />
                  <label for="email">Show results with emails</label>
                </div>
              )}
              {data.name === "Instagram" ||
              data.name === "Twitter" ||
              data.name === "SoundCloud" ? null : (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          instagram: !filterObj?.instagram,
                        });
                      }
                    }}
                    disabled={disabledButton}
                    type="checkbox"
                    id="instagram"
                    className="checkBox"
                    name="terms_and_conditions"
                    value="1"
                  />
                  <label for="instagram">
                    Show results with instagram profiles
                  </label>
                </div>
              )}
              {data.name === "YouTube" ? (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          tiktok: !filterObj?.tiktok,
                        });
                      }
                    }}
                    disabled={disabledButton}
                    type="checkbox"
                    id="tiktok"
                    className="checkBox"
                    name="terms_and_conditions"
                    value="1"
                  />
                  <label for="tiktok">Show results with Tiktok profiles</label>
                </div>
              ) : null}
            </div>
          </span>
          <div className="col-lg-12 col-md-12 col-sm-12 container-padding">
            <div className={"filter-div"}>
              <SelectComponent
                PreviousListText={PreviousListText}
                PreviousListFilterOnChange={PreviousListFilterOnChange}
                disabled={disabledButton}
                data={filterdata}
                setData={setFilterData}
                isFilter={isFilter}
                filterTextSelect={filterTextSelect}
              />

              {disabledButton ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    <div className={`loader-spinner loader-small`}>
                      <div className="inner-background"></div>
                      <div className="inner-circle"></div>
                      <div className="inner-circle-2"></div>
                    </div>{" "}
                    Your results are loading
                  </div>
                  {/* <Loader/> */}
                  <ButtonComponent
                    onClick={(e) => {
                      if (!filteredData?.length) {
                        toast.error("No data for the current search");
                      }
                      setFilter({
                        min_followers: 0,
                        min_following: 0,
                      });
                      setImportSearch(false);

                      PreviousListFilterOnChange(filterText);
                      disconnectFromWebsocket(
                        client,
                        setDisabledButton,
                        setSearch,
                        setFilterText
                      );
                    }}
                    btnclass="stop-socket"
                    text={"Stop"}
                    style={{
                      height: "50px",
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      width: "150px",
                      marginLeft: "30px",
                      border: "none",
                    }}
                  />
                </div>
              ) : null}
            </div>

            <DataTable
              data={filteredData}
              columns={columns}
              customStyles={customStyles}
              noDataComponent={
                <div className="nothing-to-show">
                  <img
                    style={{ height: "360px" }}
                    src="./Assets/Images/components/sendAnimation.svg"
                    alt=""
                  />
                </div>
              }
              pagination={!disabledButton}
              paginationResetDefaultPage={
                resetPaginationToggle || filterObj?.email || disabledButton
              }
              defaultSortFieldId={customSortcount}
              sortFunction={customSort}
              progressPending={pending}
              progressComponent={
                <Processing
                  extraClass={true}
                  header={"Processing"}
                  text={
                    data.name === "Twitter"
                      ? "It usually takes 60 to 90 seconds to generate data through Twitter."
                      : "Please wait while we are processing your request"
                  }
                />
              }
            />

            <Modal
              open={showModal}
              onCancel={() => {
                setShowModal();

                setIsOpen3(false);

                setIsOpen2(false);

                setIsOpen(false);

                setIsOpen4(false);
                setIsOpen5(false);
              }}
              footer={null}
              className="deepModal"
            >
              {selectedRow?.instagram?.instagramname ? (
                <div className="border-filter" style={{ marginTop: 10 }}>
                  <div
                    onClick={toggleContent}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>Instagram</div>
                    <div>
                      {isOpen ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen && (
                    <div
                      style={{
                        marginTop: 16,
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                      }}
                    >
                      {selectedRow?.instagram?.instagramname && (
                        <div>
                          <strong>Name:</strong>{" "}
                          {selectedRow?.instagram?.instagramname}
                        </div>
                      )}
                      {selectedRow?.instagram?.instagramusername && (
                        <div>
                          <strong>Username:</strong>{" "}
                          {selectedRow?.instagram?.instagramusername}
                        </div>
                      )}

                      {selectedRow?.instagram?.instagramemail[0] && (
                        <div>
                          <strong>Email:</strong>{" "}
                          {selectedRow?.instagram?.instagramemail[0]}
                        </div>
                      )}

                      {selectedRow?.instagram?.instagramfollowers && (
                        <div>
                          <strong>Followers:</strong>{" "}
                          {selectedRow?.instagram?.instagramfollowers}
                        </div>
                      )}

                      {selectedRow?.instagram?.instagramfollowing && (
                        <div>
                          <strong>Following:</strong>{" "}
                          {selectedRow?.instagram?.instagramfollowing}
                        </div>
                      )}

                      {selectedRow?.instagram?.instagramposts && (
                        <div>
                          <strong>Posts:</strong>{" "}
                          {selectedRow?.instagram?.instagramposts}
                        </div>
                      )}

                      <div>
                        <strong>Verified:</strong>{" "}
                        {selectedRow?.instagram?.instagramverified == true &&
                          "Yes"}
                        {selectedRow?.instagram?.instagramverified == false &&
                          "No"}
                      </div>

                      {selectedRow?.instagram?.instagramcatagory_name && (
                        <div>
                          <strong>Category:</strong>{" "}
                          {selectedRow?.instagram?.instagramcatagory_name}
                        </div>
                      )}
                      <div>
                        <strong>Business Account:</strong>{" "}
                        {selectedRow?.instagram?.instagrambusiness == true &&
                          "Yes"}
                        {selectedRow?.instagram?.instagrambusiness == false &&
                          "No"}
                      </div>
                      {selectedRow?.instagram?.instagramurl && (
                        <div>
                          <strong>Profile Link:</strong>{" "}
                          {selectedRow?.instagram?.instagramurl ? (
                            <a
                              href={selectedRow?.instagram?.instagramurl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {selectedRow?.instagram?.instagramlink && (
                        <div>
                          <strong>External Link:</strong>{" "}
                          {selectedRow?.instagram?.instagramlink ? (
                            <a
                              href={selectedRow?.instagram?.instagramlink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fan Link
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.tiktok?.name ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent2}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>TikTok</div>
                    <div>
                      {isOpen2 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen2 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.tiktok?.name && (
                        <div>
                          <strong>Name:</strong> {selectedRow?.tiktok?.name}
                        </div>
                      )}
                      {selectedRow?.tiktok?.username && (
                        <div>
                          <strong>Username:</strong>{" "}
                          {selectedRow?.tiktok?.username}
                        </div>
                      )}
                      {selectedRow?.tiktok?.email[0] && (
                        <div>
                          <strong>Email:</strong>
                          <p className="">{selectedRow?.tiktok?.email[0]}</p>
                        </div>
                      )}
                      {selectedRow?.tiktok?.followers && (
                        <div>
                          <strong>Followers:</strong>{" "}
                          {selectedRow?.tiktok?.followers}
                        </div>
                      )}
                      {selectedRow?.tiktok?.following && (
                        <div>
                          <strong>Following:</strong>{" "}
                          {selectedRow?.tiktok?.following}
                        </div>
                      )}
                      {selectedRow?.tiktok?.description && (
                        <div>
                          <strong>Description:</strong>{" "}
                          {selectedRow?.tiktok?.description}
                        </div>
                      )}
                      {selectedRow?.tiktok?.url && (
                        <div>
                          <strong>Profile URL:</strong>
                          <a
                            href={selectedRow?.tiktok?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {selectedRow?.tiktok?.url ? (
                              <a
                                href={selectedRow?.tiktok?.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Profile
                              </a>
                            ) : (
                              ""
                            )}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.soundcloud?.name ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent3}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>SoundCloud</div>
                    <div>
                      {isOpen3 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen3 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.soundcloud?.name && (
                        <div>
                          <strong>Name:</strong> {selectedRow?.soundcloud?.name}
                        </div>
                      )}

                      {selectedRow?.soundcloud?.email[0] && (
                        <div>
                          <strong>Email:</strong>{" "}
                          {selectedRow?.soundcloud?.email[0]}
                        </div>
                      )}

                      {selectedRow?.soundcloud?.url && (
                        <div>
                          <strong>Profile URL:</strong>

                          {selectedRow?.soundcloud?.url ? (
                            <a
                              href={selectedRow?.soundcloud?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.spotify?.name ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent4}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>Spotify (Artist)</div>
                    <div>
                      {isOpen4 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen4 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.spotify?.name && (
                        <div>
                          <strong>Name:</strong> {selectedRow?.spotify?.name}
                        </div>
                      )}

                      {selectedRow?.spotify?.followers && (
                        <div>
                          <strong>Followers:</strong>{" "}
                          {selectedRow?.spotify?.followers}
                        </div>
                      )}

                      {selectedRow?.spotify?.monthly_listens && (
                        <div>
                          <strong>Monthly listens:</strong>{" "}
                          {selectedRow?.spotify?.monthly_listens}
                        </div>
                      )}
                      {selectedRow?.spotify?.wikipedia && (
                        <div>
                          <strong>Wikipedia:</strong>
                          {selectedRow?.spotify?.wikipedia}
                        </div>
                      )}
                      {selectedRow?.spotify?.url && (
                        <div>
                          <strong>Profile URL:</strong>

                          {selectedRow?.spotify?.url ? (
                            <a
                              href={selectedRow?.spotify?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.youtube?.name ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent5}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>Youtube</div>
                    <div>
                      {isOpen5 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen5 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.youtube?.name && (
                        <div>
                          <strong>Name:</strong> {selectedRow?.youtube?.name}
                        </div>
                      )}
                      {selectedRow?.youtube?.username && (
                        <div>
                          <strong>Username:</strong>{" "}
                          {selectedRow?.youtube?.username}
                        </div>
                      )}
                      {selectedRow?.youtube?.email[0] && (
                        <div>
                          <strong>Email:</strong>{" "}
                          {selectedRow?.youtube?.email[0]}
                        </div>
                      )}

                      {selectedRow?.youtube?.subscribers && (
                        <div>
                          <strong>Subscriber:</strong>{" "}
                          {selectedRow?.youtube?.subscribers}
                        </div>
                      )}
                      {selectedRow?.youtube?.likes && (
                        <div>
                          <strong>Likes:</strong> {selectedRow?.youtube?.likes}
                        </div>
                      )}
                      {selectedRow?.youtube?.description && (
                        <div>
                          <strong>Description:</strong>{" "}
                          {selectedRow?.youtube?.description}
                        </div>
                      )}
                      {selectedRow?.youtube?.url && (
                        <div>
                          <strong>Profile URL:</strong>

                          {selectedRow?.youtube?.url ? (
                            <a
                              href={selectedRow?.youtube?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
            </Modal>

            <div className="csv-pdf-btns mt-4">
              <button
                disabled={filteredData?.length ? disabledButton : true}
                className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width"
                // onClick={download_pdf}
                onClick={() => {
                  if (userData?.user_metadata?.subscriptionPlan === "Free") {
                    setupgradeModal(true);
                    setupgradeAfter("PDF");
                  } else if (!disabledButton) {
                    setDownloadPDFModal(true);
                  }
                }}
                style={{
                  opacity: disabledButton ? 0.5 : 1,
                  position: "relative",
                }}
              >
                Download PDF file
                {userData?.user_metadata?.subscriptionPlan === "Free" && (
                  <img
                    src="./Assets/Images/premium.png"
                    className="download-premium"
                  />
                )}
              </button>
              <button
                disabled={filteredData?.length ? disabledButton : true}
                className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width"
                onClick={() => {
                  if (userData?.user_metadata?.subscriptionPlan === "Free") {
                    setupgradeAfter("CSV");
                    setupgradeModal(true);
                  } else if (!disabledButton && filteredData?.length) {
                    setDownloadCSVModal(true);
                  }
                }}
                style={{
                  opacity: disabledButton ? 0.5 : 1,
                  position: "relative",
                }}
              >
                Download CSV file
                {userData?.user_metadata?.subscriptionPlan === "Free" && (
                  <img
                    src="./Assets/Images/premium.png"
                    className="download-premium"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DownloadModal
        handleClose={() => {
          setDownloadCSVModal(false);
          navigate(window.location.pathname);
        }}
        csvFile={true}
        disabledButton={disabledButton}
        rows_data_for_export={rows_data_for_export}
        columns_data_for_export={columns_data_for_export}
        open={downloadCSVModal}
        data={data}
        descriptions={"Click on below button to download CSV file"}
        filterTextSelect={filterTextSelect}
      />
      <DownloadModal
        handleClose={() => {
          setDownloadPDFModal(false);
          navigate(window.location.pathname);
        }}
        csvFile={false}
        descriptions={"Click on below button to download PDF file"}
        open={downloadPDFModal}
        download_pdf={download_pdf}
      />
      <UpdateModal
        show={upgradeModal}
        setupgradeModal={setupgradeModal}
        handleClose={handleClose}
        onNext={onChangeRoute}
      />
    </>
  );
}
