import React from "react";
import Loader from "./loader";
import { CircularProgress } from "@mui/material";

export const Processing = ({ header, header2, text, extraClass }) => {
  return (
    <div className="processing processing-component">
      <h1>{header}</h1>
      <h6>{header2}</h6>
      <div
        className={`loader-spinner`}
      >
        <div className="inner-background"></div>
        <div className="inner-circle"></div>
        <div className="inner-circle-2"></div>
      </div>
      {/* <CircularProgress color="secondary" /> */}
      <p>{text}</p>
    </div>
  );
};
