import React from "react";
import ButtonComponent from "../buttonComponent";

export default function AboutUs({ navigation, userData }) {
  return (
    <div className="about-us-section" id="About">
      <div className="inner-about-us">
        <div className="mb-3">
          <h6>About Us</h6>
          <p>
            FindSocial is a platform designed to gather public information from
            various platforms.
          </p>

          <p>
            With FindSocial you can find any relevant contact and reach out to
            them. The days of manually <br /> gathering relevant contacts are
            over! With FindSocial you have a powerful tool to do this all for
            you.
          </p>

          <p>
            Find any contact from platforms such as TikTok, Spotify artists,
            Spotify playlists, SoundCloud,
            <br /> Instagram, YouTube and more.
          </p>

          <p>
            In case you are interested or have more questions,   <a href="mailto:findsocial@soarmusicgroup.com" target={"_blank"} style={{textDecoration:'none'}}>
                Contact us !
              </a>
          </p>
        </div>
        <ButtonComponent
          text={
            userData?.user_id
              ? userData?.user_metadata?.subscriptionPlan
                ? "Go to dashboard"
                : "Start for free"
              : "Start for free"
          }
          onClick={() => {
            userData?.user_id
              ? userData?.user_metadata?.subscriptionPlan
                ? navigation("/home")
                : navigation("/subscription-plan")
              : navigation("/login");
          }}
          btnclass="btn-background mb-2 start-free-btn"
        />
      </div>
    </div>
  );
}
