import React, { useState } from "react";
import ButtonComponent from "../buttonComponent";
import DrawerDialog from "../drawer";
import MenuComponent from "../MenuComponent";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
export default function HeaderComponent({ navigate, userData, setUserData }) {
  const [isShow, setIsShow] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const handleClose = () => setIsShow(false);
  const handleopen = () => setIsShow(true);

  const navigation = useNavigate();
  return (
    <>
      <div className="header-component res-tablet-none">
        <div className="inner-header-component">
          <div className="img-logo-name">
            <img src="./Assets/Images/Logo/logoname.png" alt="" />
          </div>
          <div className="header-component-child res-mobile-none">
            <a href="#Home">Home</a>
            <a href="#About">About</a>
            <a href="#Features">Features</a>
            <a href="#Pricing">Pricing</a>
          </div>

          {userData?.name ? (
            <MenuComponent
              userData={userData}
              picture={userData?.picture}
              navigation={navigation}
              setUserData={setUserData}
              className="res-mobile-none"
              name={
                userData?.user_id?.split("|")[0] === "auth0"
                  ? userData?.username
                  : userData?.name
              }
            />
          ) : (
            <div className="social-btn-parent">
              <ButtonComponent
                text={"Login"}
                className
                onClick={() => loginWithRedirect()}
                btnclass="mr-2 res-mobile-none"
              />
              <ButtonComponent
                text={"Signup"}
                onClick={() => loginWithRedirect()}
                btnclass="btn-background landing-button-signup res-mobile-none"
              />
            </div>
          )}

          <div
            className="hamburger-icon res-mobile-show"
            onClick={() => handleopen()}
          >
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="0"
              viewBox="0 0 15 15"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <DrawerDialog
        Component={componentFn}
        navigation={navigate}
        menu={true}
        loginWithRedirect={loginWithRedirect}
        userData={userData}
        // userData={userData}
        // apiCall={apiCall}
        // setApiCall={setApiCall}
        isShow={isShow}
        handleClose={handleClose}
      />
    </>
  );
}
function componentFn({ navigation, userData, loginWithRedirect }) {
  return (
    <>
      <div className="header-component res-header-component">
        <div className="inner-header-component">
          {/* <div className="img-logo-name">
          <img src="./Assets/Images/Logo/logoname.png" alt="" />
        </div> */}
          <div className="header-component-child ">
            <a href="#Home">Home</a>
            <a href="#About">About</a>
            <a href="#Features">Features</a>
            <a href="#Pricing">Pricing</a>
          </div>
          {userData?.name ? null : (
            <>
              <ButtonComponent
                text={"Login"}
                className
                onClick={() => loginWithRedirect()}
                btnclass="mr-2"
              />
              <ButtonComponent
                text={"Sign Up"}
                onClick={() => loginWithRedirect()}
                btnclass="btn-background mb-2 landing-button-signup"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
