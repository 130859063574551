import React from "react";
export default function SocailCard(props) {
  return (
    <div className="socail-card">
      <div className="inner-socail-card">
        {props?.background ? (
          <div className="twitter-image-logo">
            <img src={props.src} alt="" />
          </div>
        ) : (
          <img src={props.src} alt="" />
        )}
        <h6>{props.heading}</h6>
        <p className="socail-card-para">
          {props.text}{" "}
          <img
            src="./Assets/Images/landing/socail/Read more.png"
            onClick={() => props.handleShow(props.index)}
            style={{ display: "inline", cursor: "pointer" }}
            alt=""
          />
        </p>
      </div>
    </div>
  );
}
