import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import ButtonComponent from "../Components/buttonComponent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from "@mui/icons-material/Close";
import ButtonComponent from "../Components/buttonComponent";
import { useAuth0 } from "@auth0/auth0-react";
export default function LogoutModal({
  isShow,
  handleclose,
  setUserData,
  navigation,
}) {
  const { logout } = useAuth0();
  function deleteCookie(cookieName) {
    document.cookie =
      cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  return (
    // <Modal show={isShow} onHide={handleclose} className="subscriptionModal">
    //   <Modal.Body>
    //     <div>
    //       <h3>Warning</h3>
    //       <p>Are you sure you want to logout.</p>
    //       <div className="confirmPayment-button-parent">
    //         <ButtonComponent
    //           style={{
    //             height: "40px",
    //             width: "120px",
    //             marginLeft: "10px",
    //             whiteSpace: "pre",
    //           }}
    //           onClick={handleclose}
    //           // src={"/Assets/Images/symbols_add.png"}
    //           text={"Cancel"}
    //           btnclass="btn-background res-width"
    //         />
    //         <ButtonComponent
    //           onClick={() => {
    //             handleclose();
    //             localStorage.removeItem("accessToken");
    //             localStorage.removeItem("stripe_customer_id");
    //             localStorage.removeItem("auth-Id");
    //             toast.success("Logged out Successfully");
    //             setUserData({});
    //             navigation("/");
    //           }}
    //           // src={"/Assets/Images/Logout.png"}
    //           style={{
    //             height: "40px",
    //             width: "120px",
    //             marginLeft: "10px",
    //             whiteSpace: "pre",
    //           }}
    //           text={"Logout"}
    //           btnclass="btn-background login-Height res-width"
    //         />
    //       </div>
    //     </div>
    //   </Modal.Body>
    // </Modal>
    <Dialog
      open={isShow}
      onClose={handleclose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { width: "400px" } }}
    >
      <div className="cross-button" onClick={handleclose}>
        <CloseIcon />
      </div>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
      >
        {"Warning"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: "center" }}>
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <img
          src="./Assets/Images/NoBtn.png"
          alt="Cancel"
          className="cancel-button"
          onClick={handleclose}
        />

        <ButtonComponent
          style={{
            height: "50px",
            whiteSpace: "nowrap",
            borderRadius: "10px",
            margin: "10px",
            width: "150px",
          }}
          text={"Yes"}
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });
            handleclose();
            // document.cookie = "username=" + null;
            deleteCookie("username");
            // localStorage.removeItem("accessToken");
            // localStorage.removeItem("stripe_customer_id");
            // localStorage.removeItem("auth-Id");
            // toast.success("Logged out Successfully");
            // setUserData({});
            // navigation("/");
          }}
          btnclass="btn-background"
        />
      </DialogActions>
    </Dialog>
  );
}
