// import React from "react";
import Loader from "./loader";
// export default function InprogressComponent(props) {
//   return (
//     <div className={`InprogressComponent ${props.loader}`}>
//       <h2 className="header-Text">{props?.headerText}</h2>
//       <Loader Customloader={{ width: "105px", height: "106.12px" }} />
//       <p className="header-para">{props?.paraText}</p>
//       <h1>sameer</h1>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InprogressComponent(props) {
  const [open, setOpen] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            maxWidth: "415px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {props?.headerText}
          {/* <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <Loader />
        </DialogContent>
        <DialogContentText>
          {props?.paraText}
        </DialogContentText>
      </Dialog>
    </div>
  );
}
