import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputComponent from "./inputComponent";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { w3cwebsocket } from "websocket";
import axios from "axios";
import LoaderUI from "../UI/LoaderUI";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ProgressBar from "../UI/progressBar";
import { Progress } from "antd";

export default function SendMessageModal({
  sendMessageModal,
  changeSendMessageModal,
  cookieName,
  cookieName2,
  getCookie,
  socailIcon,
  chatSocketUrl,
  chatAPItUrl,
  uid,
  userData,
  loader,
  setLoader,
  setLoaderMsgId,
  loaderMsgId,
}) {
  const [open, setOpen] = React.useState(true);
  const [Client, setClient] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [progress, setProgress] = React.useState("0%");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const onUploadProgress = (progressEvent) => {
    const total = parseFloat(
      progressEvent.currentTarget.responseHeaders["Content-Length"]
    );
    const current = progressEvent.currentTarget.response.length;

    let percentCompleted = Math.floor((current / total) * 100);
    console.log("completed: ", percentCompleted);

    // if (percent < 100) {
    //   console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
    // }
  };
  // onUploadProgress: progressEvent => {
  //   let { progress } = this.state;
  //   progress = (progressEvent.loaded / progressEvent.total) * 100;
  //   this.setState({ progress });
  // }

  function sendMessage() {
    setLoader(true);
    let cookieAuth = getCookie(cookieName);
    var cookieAuth2 = getCookie(cookieName2);
    setLoaderMsgId(uid);
    let data =
      socailIcon === "soundclooud"
        ? {
            user: userData?.user_id,
            search: JSON.stringify(uid),
            oauth_token: cookieAuth,
            message: message,
          }
        : socailIcon === "instragram"
        ? {
            username: uid,
            sessionid: cookieAuth2,
            message: message,
          }
        : {};
    console.log(data, "data");
    changeSendMessageModal();

    var options = {
      method: "post",
      url: chatAPItUrl,
      // headers: { "content-type": "application/json" },
      onDownloadProgress: onUploadProgress,
      data: data,
    };
    axios(options)
      .then((res) => {
        console.log(res);
        toast.success("Your message has been sent successfully");
        setMessage("");
        setLoaderMsgId("");
        setTimeout(() => {
          setLoader(false);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        // changeSendMessageModal();
        toast.error(error?.response?.data?.detail);
        setLoaderMsgId("");
        setLoader(false);
      });

    // const client = w3cwebsocket(chatSocketUrl);
    // client.onopen = () => {
    //   setClient(client);
    //   client.send;
    //   JSON.stringify({
    //     user: userData?.id,
    //     search: [uid],
    //     message: message,
    //   })();
    // };
    // client.onmessage = (message) => {
    //   console.log(message);
    // };
    // client.onclose = () => {
    //   // setPending(false);
    // };
    // client.onerror = (e) => {
    //   console.log(e);
    //   // setPending(false);
    // };
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={sendMessageModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="cross-button" onClick={changeSendMessageModal}>
          <CloseIcon />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
        >
          {/* {loader ? "Sending Message..." : 
          "Send Message"} */}
          Send Message
        </DialogTitle>
        <DialogContent>
          {/* {loader ? (
            <div className="progress-bar-message-loader">
              <ProgressBar />
            </div>
          ) : ( */}
          {/* // )} */}
          <textarea
            name=""
            onChange={(ev) => setMessage(ev.target.value)}
            placeholder="Send Message here..."
            id=""
            value={message}
            className="input-Stripe"
            cols="60"
            rows="10"
          ></textarea>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            style={{
              height: "38px",
              width: "100%",
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "10px",
            }}
            text={loader ? <Spinner /> : "Send"}
            onClick={sendMessage}
            btnclass="btn-background"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
