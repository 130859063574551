import React from "react";
import { Link } from "react-router-dom";
import LandingHeader from "../Components/LandingHeader";
function FeaturesComponent(params) {
  return (
    <React.Fragment>
      <div className="LoginComponent">
        {/* <div className="main-header">
        <Link className="navbar-brand" to="/home">
          <img src="/Assets/Images/Logo/356x356.png" className="img-logo" alt="" />
          <img src="/Assets/Images/Logo/356x356.png" className="res-Img" alt="" />
        </Link>
      </div> */}
        <LandingHeader userData = {params?.userData}/>
        <div className="features-component section-2">
          <div className="inner-features-component">
            <div className="container">
              <div className="row"> 
                <div className="col-lg-12 col-md-12 col-sm-12 container-fuild-margin">
                  <div className="platform">
                    <h1 className="Select-Platform">Features</h1>
                    <p>
                      FindSocial is the innovative platform to search contacts
                      in a fast, easy and accurate manner. Through our highly
                      skilled search algorithms, you are able to search
                      real-time information and extract data easily through pdf
                      or csv file. FindSocial will also let you be able to
                      immediately reach out to the contacts that you’ve found
                      through using our platform. The social media channels that
                      you will be able to search through are the following:
                    </p>
                    <div className="selectpt">
                      <Link className="select-features">
                        <div className="select-margin-items soundcloud">
                          <img
                            src="/Assets/Images/socailMedia/soundcloud.png"
                            className="rounded-circle s1"
                            alt=""
                            srcSet=""
                            width="100px"
                            height="100px"
                          />
                          <h5 className="h5-div-heading">
                            {/* <br /> */}
                            Sound Cloud
                          </h5>
                          <p>
                            Streamline the process of finding and accessing
                            artist contact information from SoundCloud, making
                            it very easy and fast to connect with musicians,
                            bands, producers, vocalists and other artists.
                          </p>
                        </div>
                      </Link>
                      <Link className="select-features">
                        <div className="select-margin-items tiktok">
                          <img
                            src="/Assets/Images/socailMedia/tiktok.png"
                            className="rounded-circle s1"
                            alt=""
                            width="100px"
                            height="100px"
                          />
                          <h5 className="h5-div-heading">
                            {/* <br /> */}
                            TikTok
                          </h5>
                          <p>
                            The go-to tool for quickly and easily discovering
                            influencer contact information on TikTok, empowering
                            artists, businesses and brands to connect with
                            popular content creators for collaborations,
                            sponsorships, and partnerships with just a few
                            clicks.
                          </p>
                        </div>
                      </Link>
                      <Link className="select-features">
                        <div className="select-margin-items youtube">
                          <img
                            src="/Assets/Images/socailMedia/youtube.png"
                            className="rounded-circle s1"
                            alt=""
                            width="100px"
                            height="100px"
                          />
                          <h5 className="h5-div-heading">
                            {/* <br /> */}
                            YouTube
                          </h5>
                          <p>
                            The ultimate tool for effortlessly discovering and
                            accessing channel information and contact details
                            from YouTube, empowering artists, creators and
                            businesses to connect, collaborate, and grow their
                            YouTube networks with ease.
                          </p>
                        </div>
                      </Link>
                      <Link className="select-features">
                        <div className="select-margin-items spotify">
                          <img
                            src="/Assets/Images/socailMedia/spotify.png"
                            className="rounded-circle s1"
                            alt=""
                            width="100px"
                            height="100px"
                            // style={{ backgroundColor: "#1ed75f41" }}
                          />
                          <h5 className="h5-div-heading">Spotify</h5>
                          <p>
                            Easily find artist or playlist contact information
                            from Spotify, enabling musicians, record labels,
                            agencies and playlist curators to connect,
                            collaborate, and expand their networks with just a
                            few clicks, all in one user-friendly platform.
                          </p>
                        </div>
                      </Link>
                      <Link className="select-features">
                        <div className="select-margin-items instagram">
                          <img
                            src="/Assets/Images/socailMedia/instragram.png"
                            className="rounded-circle s1"
                            alt=""
                            width="100px"
                            height="100px"
                          />
                          <h5 className="h5-div-heading">
                            {/* <br /> */}
                            Instagram
                          </h5>
                          <p>
                            Quickly discover and access influencer contact
                            information on Instagram, making it simple for
                            businesses and brands to connect with popular
                            Instagram influencers for collaborations,
                            promotions, and partnerships, all in one convenient
                            platform.
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default FeaturesComponent;
