const SpotifyInstagramUrls = {
  name: "Spotify Instagram Urls",
  previousDataUrl: (user) =>
    `/previous-spotify-results/?user=${user}&result_type=Instagram_Links`,
  websocketUrl: "",
  resultType: "Instagram_Link",
  columns: [
    {
      name: "URL",
      selector: (row) => row.url,
      value: "URL",
      sortable: true,
    },
  ],
};

export default SpotifyInstagramUrls;
