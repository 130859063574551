import React from "react";
import SocailCard from "./socailCard";
export default function FeaturesSection({ handleShow }) {
  return (
    <div className="features-section" id="Features">
      <div className="inner-features-section">
        <div className="Powerful-features-text">
          <h4>Powerful features to find all the contacts you need</h4>
          <p>
            FindSocial is the innovative platform to search contacts in a fast,
            easy and accurate manner. You are able to search real-time
            information and extract data easily through pdf or csv file. The
            social media channels that you will be able to search through are
            the following:
          </p>
        </div>
        <div className="socail-section">
          <SocailCard
            src={"./Assets/Images/landing/socail/instragram.png"}
            heading={"Instagram"}
            index={0}
            handleShow={handleShow}
            text={
              "Quickly discover and access influencer contact information on Instagram, making it simple for businesses a..."
            }
          />
          <SocailCard
            src={"./Assets/Images/landing/socail/soundclould.png"}
            heading={"SoundCloud"}
            index={1}
            handleShow={handleShow}
            text={
              "Streamline the process of finding and accessing artist contact information from Soundcloud, making it v..."
            }
          />
          <SocailCard
            src={"./Assets/Images/landing/socail/shopify.png"}
            heading={"Spotify"}
            index={2}
            handleShow={handleShow}
            text={
              "Easily find artist or playlist contact information from Spotify, enabling musicians, record labels..."
            }
          />
          <SocailCard
            src={"./Assets/Images/landing/socail/tiktok.png"}
            heading={"TikTok"}
            index={3}
            handleShow={handleShow}
            text={
              "The go-to tool for quickly and easily discovering influencer contact information on TikTok, empowering..."
            }
          />
          <SocailCard
            src={"./Assets/Images/landing/socail/youtube.png"}
            heading={"YouTube"}
            index={4}
            handleShow={handleShow}
            text={
              "The ultimate tool for effortlessly discovering and accessing channel information and contact details from YouTube, empowering..."
            }
          />
          <SocailCard
            src={"./Assets/Images/landing/socail/twitter.webp"}
            background={true}
            heading={"Twitter"}
            index={5}
            handleShow={handleShow}
            text={
              "Explore and obtain contact information for influencers, thought leaders, and trendsetters on Twitter. Simplifying the process..."
            }
          />
        </div>
      </div>
    </div>
  );
}
