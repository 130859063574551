import React from "react";
import ImageComponent from "./imageComponent";
export default function GoogleButton(props) {
  return (
    <button className="socialBtn google" onClick={props.handleChange}>
      <ImageComponent src="/Assets/Images/socailMedia/google.png" />
      Google
    </button>
  );
}
