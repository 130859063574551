import React, { useEffect, useState } from "react";
import { useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./utils/Utils";
import ButtonComponent from "./buttonComponent";
import InputComponent from "./inputComponent";
import ImageComponent from "./imageComponent";
import { useNavigate } from "react-router-dom";
import InprogressComponent from "./inprogressComponent";
import { toast } from "react-toastify";
import ModalDialog from "./modalcomponent";
import axios from "axios";
import Payment from "payment";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);
const stripeintent = require("stripe")(
  process.env.REACT_APP_STRIPE_CLIENT_SECRET
);

export default function 



StripeComponent({
  apiCall,
  setApiCall,
  userData,
  setUserData,
  picingData,
  handleNext,
  cardDetail,
  expiryMonth,
  expiryDate,
  cardNumber,
  setCardDetail,
  setExpiryMonth,
  setExpiryDate,
  setCardNumber,
  name,
  setName,
  setCouponCode,
  couponCode,
  ...props
}) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        url={props.url}
        userData={userData}
        apiCall={apiCall}
        setApiCall={setApiCall}
        setUserData={setUserData}
        handleNext={handleNext}
        picingData={picingData}
        setName={setName}
        setCardDetail={setCardDetail}
        cardDetail={cardDetail}
        setExpiryMonth={setExpiryMonth}
        expiryMonth={expiryMonth}
        setExpiryDate={setExpiryDate}
        expiryDate={expiryDate}
        setCardNumber={setCardNumber}
        cardNumber={cardNumber}
        setCouponCode={setCouponCode}
        couponCode={couponCode}
      />
    </Elements>
  );
}

export const CheckoutForm = ({
  apiCall,
  setUserData,
  userData,
  setApiCall,
  picingData,
  handleNext,
  cardDetail,
  expiryMonth,
  expiryDate,
  cardNumber,
  setCardDetail,
  setExpiryMonth,
  setExpiryDate,
  setCardNumber,
  name,
  setName,
  setCouponCode,
  couponCode,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();
  // const [name, setName] = useState("");
  // const [cardDetail, setCardDetail] = useState({
  //   number: "",
  //   exp_month: "",
  //   exp_year: "",
  //   cvc: "",
  // });
  // const [expiryMonth, setExpiryMonth] = useState("");
  // const [expiryDate, setExpiryDate] = useState("");
  // const [cardNumber, setCardNumber] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [loader, setLoader] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const navigation = useNavigate();
  // let subscriptionPlan = props?.url.searchParams.get("subscription-Plan");
  // let subscriptionPlan = "subscription-Plan";
  useEffect(()=>{
    console.log(userData,"userData");
  },[userData])
  // const handleConfirm = async (event) => {
  //   setLoader(true);
  //   setmodalOpen(false);
  //   if (subscriptionPlan === "Free") {
  //     let user_metadata = userData?.user_metadata?.subscriptionPlan
  //       ? {
  //           subscriptionPlan: subscriptionPlan,
  //           remainingCredits:
  //             subscriptionPlan === "Free"
  //               ? userData?.user_metadata?.remainingCredits + 100
  //               : null,
  //           credits:
  //             subscriptionPlan === "Free"
  //               ? userData?.user_metadata?.credits + 100
  //               : null,
  //           freeVersion: "claimed",
  //         }
  //       : {
  //           subscriptionPlan: subscriptionPlan,
  //           remainingCredits: subscriptionPlan === "Free" ? 100 : null,
  //           credits: subscriptionPlan === "Free" ? 100 : null,
  //           freeVersion: "claimed",
  //         };
  //     var options = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
  //       headers: { "content-type": "application/json" },
  //       data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
  //     };
  //     axios(options)
  //       .then((res) => {
  //         var options = {
  //           method: "PATCH",
  //           url: `${
  //             process.env.REACT_APP_AUTH0_API_DOMAIN
  //           }/api/v2/users/${JSON.parse(localStorage.getItem("auth-Id"))}`,
  //           headers: {
  //             authorization: `Bearer ${res.data?.access_token}`,
  //             "content-type": "application/json",
  //           },
  //           data: { user_metadata: user_metadata },
  //         };

  //         axios
  //           .request(options)
  //           .then(function (response) {
  //             // setApiCall(!apiCall);
  //             setUserData(response.data);
  //             setLoader(false);
  //             setmodalOpen(false);
  //             navigation("/home");
  //             setCardNumber("");
  //             setExpiryDate("");
  //             setExpiryMonth("");
  //             setName("");
  //             return toast.success("Payment successfully confirmed");
  //           })
  //           .catch(function (error) {
  //             return console.error(error);
  //           });
  //       })
  //       .catch(function (error) {
  //         return console.error(error);
  //       });
  //     return;
  //   } else {
  //     let user_metadata = userData?.user_metadata?.subscriptionPlan
  //       ? {
  //           subscriptionPlan: subscriptionPlan,
  //           remainingCredits:
  //             subscriptionPlan === "Free"
  //               ? 100 + userData?.user_metadata?.remainingCredits
  //               : subscriptionPlan === "Starter"
  //               ? 1000 + userData?.user_metadata?.remainingCredits
  //               : subscriptionPlan === "Medium"
  //               ? 10000 + userData?.user_metadata?.remainingCredits
  //               : subscriptionPlan === "Pro"
  //               ? 50000 + userData?.user_metadata?.remainingCredits
  //               : null,
  //           credits:
  //             subscriptionPlan === "Free"
  //               ? 100 + userData?.user_metadata?.credits
  //               : subscriptionPlan === "Starter"
  //               ? 1000 + userData?.user_metadata?.credits
  //               : subscriptionPlan === "Medium"
  //               ? 10000 + userData?.user_metadata?.credits
  //               : subscriptionPlan === "Pro"
  //               ? 50000 + userData?.user_metadata?.remainingCredits
  //               : null,
  //         }
  //       : {
  //           subscriptionPlan: subscriptionPlan,
  //           remainingCredits:
  //             subscriptionPlan === "Free"
  //               ? 100
  //               : subscriptionPlan === "Starter"
  //               ? 1000
  //               : subscriptionPlan === "Medium"
  //               ? 10000
  //               : subscriptionPlan === "Pro"
  //               ? 50000
  //               : null,
  //           credits:
  //             subscriptionPlan === "Free"
  //               ? 100
  //               : subscriptionPlan === "Starter"
  //               ? 1000
  //               : subscriptionPlan === "Medium"
  //               ? 10000
  //               : subscriptionPlan === "Pro"
  //               ? 50000
  //               : null,
  //         };
  //     var params = { id: JSON.parse(localStorage.getItem("auth-Id")) };
  //     var options = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
  //       headers: { "content-type": "application/json" },
  //       data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
  //     };
  //     axios(options)
  //       .then((res) => {
  //         var options = {
  //           method: "PATCH",
  //           url: `${
  //             process.env.REACT_APP_AUTH0_API_DOMAIN
  //           }/api/v2/users/${JSON.parse(localStorage.getItem("auth-Id"))}`,
  //           headers: {
  //             authorization: `Bearer ${res.data?.access_token}`,
  //             "content-type": "application/json",
  //           },
  //           data: {
  //             user_metadata: user_metadata,
  //           },
  //         };

  //         axios
  //           .request(options)
  //           .then(function (response) {
  //             // console.log(response.data, "response.setUserData");
  //             setUserData(response.data);
  //             // setApiCall(!apiCall);
  //             setLoader(false);
  //           })
  //           .catch(function (error) {
  //             console.error(error);
  //           });
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       });
  //     setCardDetail({
  //       number: "",
  //       exp_month: "",
  //       exp_year: "",
  //       cvc: "",
  //     });
  //     setPaymentIntentId("");
  //   }
  // };
  // const handleCancel = async (event) => {
  //   setLoader(true);
  //   setmodalOpen(false);
  //   stripeintent.paymentIntents
  //     .cancel(paymentIntentId)
  //     .then((success) => {
  //       toast.success("Payment cancelled");
  //       setmodalOpen(false);
  //       setCardNumber("");
  //       setExpiryDate("");
  //       setExpiryMonth("");
  //       setName("");
  //       setCardDetail({
  //         number: "",
  //         exp_month: "",
  //         exp_year: "",
  //         cvc: "",
  //       });
  //       setLoader(false);
  //       setPaymentIntentId("");
  //     })
  //     .catch((err) => {
  //       setPaymentIntentId("");
  //       setmodalOpen(false);
  //       toast.error(err.error.message);
  //       setCardNumber("");
  //       setExpiryDate("");
  //       setName("");
  //       setLoader(false);
  //       setExpiryMonth("");
  //       setCardDetail({
  //         number: "",
  //         exp_month: "",
  //         exp_year: "",
  //         cvc: "",
  //       });
  //     });
  // };
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   handleNext();
  //   // console.log(picingData?.id);
  //   try {
  //     const paymentMethod = await stripeintent.paymentMethods.create({
  //       type: "card",
  //       card: {
  //         number: cardDetail?.number,
  //         exp_month: cardDetail?.exp_month,
  //         exp_year: cardDetail?.exp_year,
  //         cvc: cardDetail?.cvc,
  //       },
  //     });
  //     console.log(paymentMethod, "paymentMethod");
  //     const paymentMethodAttached = await stripeintent.paymentMethods.attach(
  //       paymentMethod?.id,
  //       { customer: JSON.parse(localStorage.getItem("stripe_customer_id")) }
  //     );
  //     console.log(paymentMethodAttached, "paymentMethod");

  //     const subscription = await stripeintent.subscriptions.create({
  //       customer: JSON.parse(localStorage.getItem("stripe_customer_id")),
  //       // items: [{
  //       //   price: priceId,
  //       // }],
  //       items: [{ price: picingData?.id }],
  //       // payment_settings: { save_default_payment_method: "on_subscription" },
  //       expand: ["latest_invoice.payment_intent"],
  //       default_payment_method: paymentMethodAttached?.id,
  //     });
  //     // const subscriptionUpdate = await stripe.subscriptions.update(
  //     //   subscription?.id,
  //     //   {
  //     //     payment_settings: {
  //     //       payment_method_types: ["card"],
  //     //     },
  //     //   }
  //     // );
  //     console.log(subscription);
  //   } catch (error) {
  //     console.log({ error: { message: error.message } });
  //     toast.error(error.message || "Invalid error");
  //   }
  // };
  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   if (!loader) {
  //     setLoader(true);
  //     if (!elements) {
  //       alert("elements has not yet loaded.");
  //       return;
  //     }
  //     if (!stripe) {
  //       alert("Stripe.js has not yet loaded.");
  //       return;
  //     }
  //     let number = cardDetail?.number;
  //     let exp_month = cardDetail?.exp_month;
  //     let exp_year = cardDetail?.exp_year;
  //     let cvc = cardDetail?.cvc;
  //     if (!number && !exp_month && !exp_year && !cvc) {
  //       setLoader(false);
  //       setCardNumber("");
  //       setExpiryDate("");
  //       setName("");
  //       setExpiryMonth("");
  //       setCardDetail({
  //         number: "",
  //         exp_month: "",
  //         exp_year: "",
  //         cvc: "",
  //       });
  //       toast.error("please fill all fields");
  //       // console.log(number && exp_month && exp_year && cvc);
  //       return;
  //     }
  //     if (subscriptionPlan === "Free") {
  //       let validNumber = Payment.fns.validateCardNumber(number);
  //       let validCVC = Payment.fns.validateCardCVC(cvc);
  //       let validexp = Payment.fns.validateCardExpiry(
  //         exp_month + "/" + exp_year
  //       );

  //       if (!validNumber) {
  //         setLoader(false);
  //         setCardNumber("");
  //         setExpiryDate("");
  //         setName("");
  //         setExpiryMonth("");
  //         setCardDetail({
  //           number: "",
  //           exp_month: "",
  //           exp_year: "",
  //           cvc: "",
  //         });
  //         toast.error("Your card is not valid!");
  //         return;
  //       }
  //       if (!validCVC) {
  //         setLoader(false);
  //         toast.error("Your card is not valid!");
  //         return;
  //       }
  //       if (!validexp) {
  //         setLoader(false);
  //         toast.error("Your card is not valid!");
  //         return;
  //       }
  //       if (userData?.user_metadata?.freeVersion === "claimed") {
  //         setLoader(false);
  //         setCardNumber("");
  //         setExpiryDate("");
  //         setName("");
  //         setExpiryMonth("");
  //         setCardDetail({
  //           number: "",
  //           exp_month: "",
  //           exp_year: "",
  //           cvc: "",
  //         });
  //         return toast.error("You have availed free version");
  //       }
  //       setmodalOpen(true);
  //       return;
  //     }
  //     try {
  //       const price = await stripeintent.prices.create({
  //         product: `${process.env.REACT_APP_STRIPE_PRODUCT_50_ID}`,
  //         unit_amount: 25,
  //         currency: "EUR",
  //         // recurring: { interval: "month" },
  //         // lookup_key: "standard_monthly",
  //         // transfer_lookup_key: true,
  //       });
  //     } catch (error) {
  //       console.log(error, "error");
  //     }
  //     // try {
  //     //   const paymentMethod = await stripeintent.paymentMethods.create({
  //     //     type: "card",

  //     //     card: {
  //     //       number,
  //     //       exp_month,
  //     //       exp_year,
  //     //       cvc,
  //     //     },
  //     //   });
  //     //   // console.log(paymentMethod, "paymentMethod");
  //     //   if (paymentMethod?.error) {
  //     //     setLoader(false);
  //     //     setCardNumber("");
  //     //     setExpiryDate("");
  //     //     setExpiryMonth("");
  //     //     setName("");
  //     //     setCardDetail({
  //     //       number: "",
  //     //       exp_month: "",
  //     //       exp_year: "",
  //     //       cvc: "",
  //     //     });
  //     //     toast.error(paymentMethod?.error?.message || "Invalid");
  //     //     return;
  //     //   }
  //     //   stripeintent.paymentMethods
  //     //     .attach(paymentMethod.id, {
  //     //       customer: JSON.parse(localStorage.getItem("stripe_customer_id")),
  //     //       // name: name,
  //     //       // authId: localStorage.getItem("auth-Id"),
  //     //     })
  //     //     .then((res) => {
  //     //       stripeintent.paymentIntents
  //     //         .create({
  //     //           payment_method: paymentMethod.id,
  //     //           amount:
  //     //             subscriptionPlan === "Starter"
  //     //               ? 50
  //     //               : subscriptionPlan === "Medium"
  //     //               ? 100
  //     //               : subscriptionPlan === "Pro"
  //     //               ? 200
  //     //               : null,
  //     //           currency: "EUR",
  //     //           confirm: false,
  //     //           customer: JSON.parse(
  //     //             localStorage.getItem("stripe_customer_id")
  //     //           ),
  //     //         })
  //     //         .then((success) => {
  //     //           setmodalOpen(true);
  //     //           setPaymentIntentId(success?.id);
  //     //           setCardNumber("");
  //     //           setExpiryDate("");
  //     //           setExpiryMonth("");
  //     //           setName("");
  //     //           setCardDetail({
  //     //             number: "",
  //     //             exp_month: "",
  //     //             exp_year: "",
  //     //             cvc: "",
  //     //           });
  //     //           setLoader(false);
  //     //         })
  //     //         .catch((err) => {
  //     //           setLoader(false);
  //     //           setCardNumber("");
  //     //           setName("");
  //     //           setExpiryDate("");
  //     //           setExpiryMonth("");
  //     //           setCardDetail({
  //     //             number: "",
  //     //             exp_month: "",
  //     //             exp_year: "",
  //     //             cvc: "",
  //     //           });
  //     //           toast.error(err?.message || "Invalid payment");
  //     //           // console.log(err?.message, "Error");
  //     //         });
  //     //       // if (paymentIntent.error) {
  //     //       //   console.log(paymentIntent.error.message, "ERROR`");
  //     //       //   return;
  //     //       // } else {
  //     //       //   try {
  //     //       //     console.log(paymentIntent?.id, "paymentIntent?.id");
  //     //       //     let id = paymentIntent?.id;
  //     //       //     let confirm = stripeintent.paymentIntents.confirm({
  //     //       //       id,
  //     //       //     });
  //     //       //     console.log(confirm, "confirm");
  //     //       //   } catch (err) {
  //     //       //     console.log(err, "Error");
  //     //       //   }
  //     //       // }
  //     //     })
  //     //     .catch((error) => {
  //     //       console.log(error);
  //     //       switch (error?.type) {
  //     //         case "StripeCardError":
  //     //           console.log(`A payment error occurred: ${error?.message}`);
  //     //           toast.error(`A payment error occurred: ${error?.message}`); // If some error occurs
  //     //           break;
  //     //         case "StripeInvalidRequestError":
  //     //           console.log("An invalid request occurred.");
  //     //           toast.error(`An invalid request occurred :  ${error?.message}`); // If some error occurs
  //     //           break;
  //     //         default: // If some error occurs
  //     //           console.log(
  //     //             "Another problem occurred, maybe unrelated to Stripe."
  //     //           );
  //     //           toast.error(
  //     //             "Another problem occurred, maybe unrelated to Stripe."
  //     //           );
  //     //           break;
  //     //       }
  //     //       console.log(error?.message);
  //     //     });
  //     // } catch (error) {
  //     //   console.log(error);
  //     //   switch (error?.type) {
  //     //     case "StripeCardError":
  //     //       console.log(`A payment error occurred: ${error?.message}`);
  //     //       toast.error(`A payment error occurred: ${error?.message}`); // If some error occurs
  //     //       break;
  //     //     case "StripeInvalidRequestError":
  //     //       console.log("An invalid request occurred.");
  //     //       toast.error(`An invalid request occurred :  ${error?.message}`); // If some error occurs
  //     //       break;
  //     //     default: // If some error occurs
  //     //       console.log("Another problem occurred, maybe unrelated to Stripe.");
  //     //       toast.error("Another problem occurred, maybe unrelated to Stripe.");
  //     //       break;
  //     //   }
  //     // }
  //   }
  // };

  return (
    <form className="form-main form-stripe">
      {loader && (
        <InprogressComponent
          headerText={"Checkout Processing"}
          paraText={
            "Please wait while the page is loading."
          }
          loader={loader}
        />
      )}
      {!loader && (
        <>
          <h1 className="SubscriptionPlan-Header-Text">Add a Card</h1>
          <p className="SubscriptionPlan-Header-para">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <div className="payment-header">
            <div className="payment-child">
              <ImageComponent
                style={{ marginRight: "10px" }}
                src={"/Assets/Images/Success.png"}
              />

              <h5 className="payment-header-text">Confirm Payment</h5>
            </div>
            <div className="payment-child">
              <img src="/Assets/Images/CardLogo's.png" alt="img" srcset="" />
              {/* <ImageComponent src={"/Assets/Images/cards/CardLogo's.png"} /> */}
              {/* <ImageComponent src={"/Assets/Images/cards/Visa.png"} />
              <ImageComponent src={"/Assets/Images/cards/Troy.png"} /> */}
            </div>
          </div>
          {picingData?.heading !== "Free" ? (
            <>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Card number</h6>
                  <p>Enter the 16-digit card number on the card</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={cardNumber}
                    placeholder="Card number"
                    onChange={(ev) => {
                      let objj = { ...cardDetail };
                      setCardNumber(
                        formatCreditCardNumber(ev.target.value).cardNumber
                      );
                      objj.number = ev.target.value.replace(/\s+/g, "");
                      setCardDetail(objj);
                    }}
                  />
                </div>
              </div>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Card owner</h6>
                  <p>Enter the name on the card</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={name}
                    placeholder="Owner name"
                    onChange={(ev) => setName(ev.target.value)}
                  />
                </div>
              </div>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Expiry date</h6>
                  <p>Enter the expration date of the card</p>
                </div>
                <div className="form-child-section-2">
                  <div className="expire-parent-1 ">
                    <InputComponent
                      style={{ width: "56px" }}
                      classinput="input-Stripe"
                      value={expiryMonth}
                      placeholder="mm"
                      type={"number"}
                      max={2}
                      onChange={(ev) => {
                        let objj = { ...cardDetail };
                        setExpiryMonth(formatExpirationDate(ev.target.value));
                        objj.exp_month = Number(ev.target.value);
                        setCardDetail(objj);
                      }}
                    />
                    <div className="expire-Line">/</div>
                    <InputComponent
                      max={2}
                      style={{ width: "56px" }}
                      classinput="input-Stripe"
                      value={expiryDate}
                      placeholder="YY"
                      type={"number"}
                      onChange={(ev) => {
                        let objj = { ...cardDetail };
                        setExpiryDate(formatExpirationDate(ev.target.value));
                        objj.exp_year = Number(ev.target.value);
                        setCardDetail(objj);
                      }}
                    />
                  </div>
                  <div className="expire-parent-2 security-none">
                    <div className="form-child-section-1">
                      <h6>CVV2</h6>
                      <p style={{ margin: "0px" }}>Security code</p>
                    </div>
                    <div className="form-child-CCV">
                      <InputComponent
                        max={3}
                        classinput="input-Stripe"
                        value={cardDetail.cvc}
                        onChange={(ev) => {
                          let objj = { ...cardDetail };
                          objj.cvc = formatCVC(ev.target.value);
                          setCardDetail(objj);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-main-child res-security-block">
                <div className="form-child-section-1">
                  <h6>CVV2</h6>
                  <p>Security code</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={cardDetail.cvc}
                    onChange={(ev) => {
                      let objj = { ...cardDetail };
                      objj.cvc = formatCVC(ev.target.value);
                      setCardDetail(objj);
                    }}
                  />
                </div>
              </div>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Coupen code</h6>
                  <p>Enter the code copen (if any)</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={couponCode}
                    placeholder="coupen code"
                    onChange={(ev) => setCouponCode(ev.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="form-main-child">
              <div className="form-child-section-1">
                <h6>Email</h6>
                {/* <p>Enter the 16-digit card number on the card</p> */}
              </div>
              <div className="form-child-section-2">
                <InputComponent
                  classinput="input-Stripe"
                  value={userData?.email}
                  disabled={true}
                  placeholder="email"
                />
              </div>
            </div>
          )}
          <div className="border-Line"></div>
        </>
      )}
      {/* <ModalDialog
          confirmLoader={confirmLoader}
        handleClose={() => setmodalOpen(false)}
        subscriptionPlan={subscriptionPlan}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        isShow={modalOpen}
      /> */}
    </form>
  );
};
