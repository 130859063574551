import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SubscriptionPlan from "../UI/SubscriptionPlan";
import ButtonComponent from "./buttonComponent";
import HorizontalStepper from "./HorizontalStepper";
import BasicTabs from "./BasicTabs";
import UpgradeModal from "./UpgradeModal";
import LinkIdModal from "./LinkIdModal";
import { useState } from "react";

export default function PricingModal({
  changeUpgradeModal,
  pricingModal,
  openLinkModal,
  userData,
  setApiCall,
  apiCall,
  setUserData,
  openlinkModal,
  handleClose,
  setsendMessageModal,
  sendMessageModal,
}) {
  //   const [openPricingModal, setOpenPricingModal] = React.useState(false);
  //   const [pricingModal, setpricingModal] = useState(f) ;
  // const [linkIdModal, setlinkIdModal] = useState(false);

  const handleClickOpen = () => {
    // setOpenPricingModal(true);
    // console.log("clicked")
  };

  const handleClosePricingModal = () => {
    // setOpenPricingModal(false);
    // pricingModal(false);
  };

  const handleClick = () => {
    console.log("clicked");
    changeUpgradeModal(true);
  };

  // const changeLinkModalState = ()=>{
  //   setlinkIdModal(false)
  //   console.log("linkidmodal" , linkIdModal);
  // }

  // const openLinkModal = ()=>{
  //   setlinkIdModal(true);
  //   changeUpgradeModal(false)
  // }
  return (
    <div>
      {/* <ButtonComponent
        onClick={handleClick}
        style={{
          height: "45px",
          width: "95%",
          whiteSpace: "nowrap",
          borderRadius: "10px",
          margin: "10px",
        }}
        text={"View plans"}
        btnclass="btn-background"
      /> */}

      {/* {openPricingModal ? ( */}
      <Dialog
        open={pricingModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "90vw", maxWidth: "90vw" } }}
      >
        <div className="SubscriptionPlan SubscriptionPlan-modal">
          <h1>Select Your Plan</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus
            possimus cumque aliquam laboriosam, <br />
            consequuntur omnis fuga. Sed, non. Laborum, rem officiis!
          </p>
          <HorizontalStepper
            openlinkModal={openlinkModal}
            updateFromModal={true}
            userData={userData}
            setApiCall={setApiCall}
            apiCall={apiCall}
            setUserData={setUserData}
          />
        </div>
      </Dialog>
      {/* <LinkIdModal linkIdModal={linkIdModal} changeLinkModalState={changeLinkModalState}/> */}
    </div>
  );
}
