import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputComponent from "./inputComponent";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { Spinner } from "react-bootstrap";

export default function CancelModal({ onSubscribtionEnd, cancelLoader }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <img
        src="./Assets/Images/cancelBtn.png"
        alt="cancel"
        className="cancel-button"
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "400px" } }}
      >
        <div className="cross-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
        >
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Are you sure you want to cancel your plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <img
            src="./Assets/Images/NoBtn.png"
            alt="Cancel"
            className="cancel-button"
            onClick={handleClose}
          />

          <ButtonComponent
            style={{
              height: "50px",
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "10px",
              width: "150px",
            }}
            text={cancelLoader ? <Spinner color="#fff" /> : "Yes"}
            onClick={onSubscribtionEnd}
            btnclass="btn-background"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
