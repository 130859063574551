import React, { useEffect, useState } from "react";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function Loader() {
  const [progressStartValue, setProgressStartValue] = useState(0);
  // useEffect(() => {
  //   const progressEndValue = 90;
  //   const speed = 3000;

  //   const progress = setInterval(() => {
  //     setProgressStartValue(progressStartValue + 1);
  //   }, speed);

  //   return () => {
  //     clearInterval(progress);
  //   };
  // }, []);

  // const circularProgressStyles = {
  //   position: "relative",
  //   height: "100px",
  //   width: "100px",
  //   borderRadius: "50%",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   // animation: 'rotate 5s infinite linear',
  //   // transform: "translate(-50%, -50%) scale(0.8)",
  //   transition: "all 0.2s",
  //   background: `#01C38D`,
  //   // backgroundImage: `linear-gradient(${90 - (progressStartValue * 1.35)}deg, #01C38D 12.66%, #7728B5 100%)`
  //   // background: `conic-gradient(#7d2ae8 ${progressStartValue * 3.6}deg, #ededed 0deg)`,
  //   // background: `linear-gradient(${progressStartValue * 3.6}deg, #01C38D 12.66%, #7728B5 100%)`
  //   // background: linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%);
  // };

  // const progressValueStyles = {
  //   position: "relative",
  //   fontSize: "40px",
  //   fontWeight: 600,
  //   color: "#7d2ae8",
  // };

  // const textStyles = {
  //   fontSize: "30px",
  //   fontWeight: 500,
  //   color: "#606060",
  // };

  // const circularProgressBeforeStyles = {
  //   content: '""',
  //   position: "absolute",
  //   height: "90px",
  //   width: "90px",
  //   borderRadius: "50%",
  //   backgroundColor: "#fff",
  // };
  const [percentage, setPercentage] = useState(0);

  const idCSS = "hello";
  setTimeout(() => {
    if (percentage !== 100) {
      setPercentage(percentage + 1);
    }
  }, 35);
  return (
    // <div >
    //   <div style={circularProgressStyles} className="circular-progress">
    //     <span style={progressValueStyles} className="progress-value">
    //       {/* {progressStartValue}% */}
    //     </span>
    //     <div style={circularProgressBeforeStyles}></div>
    //   </div>
    // </div>
    // <div class="loader-spinner">
    //   <div class="inner-background"></div>
    //   <div class="inner-circle"></div>
    // </div>
    // <div class="progress-bar">
    //   <progress
    //     value="75"
    //     min="0"
    //     max="100"
    //     style={{ visibility: "hidden", height: "0", width: "0" }}
    //   >
    //     75%
    //   </progress>
    // </div>
    // <div class="circular">
    //   <div class="inner"></div>
    //   <div class="outer"></div>
    //   <div class="circle">
    //     <div class="dot">
    //       <span></span>
    //     </div>
    //     <div class="bar left">
    //       <div class="progress"></div>
    //     </div>
    //     <div class="bar right">
    //       <div class="progress"></div>
    //     </div>
    //   </div>
    // </div>
    <div className="loader-spinner-parent">
      <GradientSVG />
      <CircularProgressbarWithChildren
        strokeWidth={8}
        value={percentage}
        styles={{
          path: { stroke: `url(#${idCSS})`, height: "100%" },
          trail: {
            stroke: "#fff",
          },
        }}
      >
        {percentage === 100 && (
          <div className="loader-spinner-children">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height={25}
              width={25}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
            </svg>
          </div>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default Loader;
function GradientSVG() {
  const idCSS = "hello";
  const gradientTransform = `rotate(90deg)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="12.66%" stopColor="#01C38D" />
          <stop offset="100%" stopColor="#7728B5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
