import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { webAuth, authentication, lock } from "../Data/Auth/webAuth";
import InprogressComponent from "../Components/inprogressComponent";
import { toast } from "react-toastify";
import axios from "axios";
import jwt_decode from "jwt-decode";
import FooterComponent from "../Components/footerComponent";
import AuthSwiper from "./authSwiper";
import InputComponent from "../Components/inputComponent";
import ButtonComponent from "../Components/buttonComponent";
import GoogleButton from "../Components/googleButton";
import FacebookButton from "../Components/facebookButton";
import { useAuth0 } from "@auth0/auth0-react";
function AuthenticateComponent({ setApiCall, apiCall, userData }) {
  const [loader, setLoader] = useState(false);
  const [appMetadata, setAppMetadata] = useState(null);
  const { isAuthenticated, isLoading } = useAuth0();
  // const fetchAppMetadata = async (id) => {
  //   try {
  //     const response = await axios.post(
  //       "https://eprecisio-scrapper.eu.auth0.com/api/v2/apps/K6641QHAnd4kggpBBW0CfusCLJUxnevc",
  //       // 'https://dev-j62k6zg0q8wlxrmp.us.auth0.com/api/v2/apps/63d7d79fd96f7e3065af5ff9',
  //       {
  //         headers: {
  //           Authorization: `Bearer ${id}`,
  //         },
  //       }
  //     );
  //     setAppMetadata(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const navigation = useNavigate();
  // useEffect(() => {
  //   if (!localStorage.getItem("accessToken")) {
  //     // navigation("/home");
  //   }
  // }, [localStorage.getItem("accessToken")]);
  const processHash = async () => {
    if (userData?.user_metadata?.subscriptionPlan) {
      navigation("/home");
    } else if (!userData?.user_metadata?.subscriptionPlan) {
      navigation("/subscription-plan");
    }
    // webAuth.parseHash({ hash: location.hash }, function (err, authResult) {
    //   if (err) {
    //     navigation("/login");
    //     toast.error(err.errorDescription);
    //     return console.log(err);
    //   }
    //   const decodedUser = jwt_decode(authResult?.accessToken);
    //   // console.log(decodedUser);
    //   var options = {
    //     method: "post",
    //     url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
    //     headers: { "content-type": "application/json" },
    //     data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
    //   };
    //   axios(options)
    //     .then((res) => {
    //       // console.log(res, "access-response");
    //       var options = {
    //         method: "GET",
    //         url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${decodedUser?.sub}`,
    //         // params: { q: 'email:"dani@gmail.com"', search_engine: "v2" },
    //         headers: { authorization: `Bearer ${res.data?.access_token}` },
    //       };
    //       axios
    //         .request(options)
    //         .then(function (response) {
    //           localStorage.setItem(
    //             "stripe_customer_id",
    //             JSON.stringify(response.data?.app_metadata?.stripe_customer_id)
    //           );
    //           localStorage.setItem(
    //             "auth-Id",
    //             JSON.stringify(response?.data?.user_id)
    //           );
    //           // if (!response?.data?.email_verified) {
    //           //   navigation("/verify-account");
    //           //   localStorage.setItem(
    //           //     "token",
    //           //     JSON.stringify(authResult?.accessToken)
    //           //   );
    //           // } else
    //           if (response?.data?.user_metadata?.subscriptionPlan) {
    //             console.log(
    //               response?.data?.user_metadata?.subscriptionPlan,
    //               "response?.data?.user_metadata?.subscriptionPlan"
    //             );
    //             navigation("/home");
    //             localStorage.setItem(
    //               "accessToken",
    //               JSON.stringify(authResult?.accessToken)
    //             );
    //           } else {
    //             localStorage.setItem(
    //               "accessToken",
    //               JSON.stringify(authResult?.accessToken)
    //             );
    //             navigation("/subscription-plan");
    //           }
    //           toast.success("You have logged in successfully");
    //           // console.log(response.data);
    //           setApiCall(!apiCall);
    //         })
    //         .catch(function (error) {
    //           console.error(error);
    //         });
    //     })
    //     .catch((err) => {
    //       console.log(err, "error-response");
    //     });
    // });
  };
  useEffect(() => {
    if (!isLoading && isAuthenticated && userData?.user_id) {
      document.cookie = "username=" + userData?.user_id;
      processHash();
    } else if (!isAuthenticated && !isLoading) {
      navigation("/");
    }
  }, [isLoading, userData, isAuthenticated]);
  return (
    <React.Fragment>
      <div className="LoginComponent mainComponent">
        {/* <div className="main-header">
          <Link className="navbar-brand" to="/home">
            <img src="/Assets/Images/Logo/356x356.png" alt="" />
          </Link>
        </div> */}
        <div className="section-2">
          <div
            className="form-main"
            // style={props.loader ? { justifyContent: "center" } : {}}
            // onSubmit={handleChange}
          >
            {/* <span ><span>Back</span></span> */}
            <div
              className="back-arrow-text"
              // onClick={() => {
              //   if (redirect) {
              //     navigation(`/${redirect}`);
              //   } else {
              //     navigation("/");
              //   }
              // }}
              // location == "/register" ?
              // : navigation("/register")
            >
              <div>
                <img src="/Assets/Images/back.png" alt="" />
              </div>
              <div>Back</div>
            </div>
            <div className="text-innerText">
              <div className="header-Text">Login</div>
              <div className="header-inner-text">
                Please fill the form below to recieve a qoute for your project.{" "}
                <br /> Fell free to add detail as much as needed
              </div>
            </div>
            <div className="inner-form-main">
              <div className="socail-parent">
                <GoogleButton handleChange={() => {}} />
                <FacebookButton handleChange={() => {}} />
              </div>
              <div className="header-text-center"> Or </div>
              <form style={{ width: "100%" }}>
                {/* {props.text === "Register" && (
                  <InputComponent
                    heading={"Name"}
                    value={props?.object?.username}
                    classinput={"inputBg"}
                    placeholder={"Name"}
                    type={"text"}
                    icon={""}
                    required={true}
                    onChange={(ev) =>
                      props?.setObject({
                        ...props?.object,
                        username: ev.target?.value,
                      })
                    }
                  />
                )} */}
                <InputComponent
                  heading={"Email"}
                  // value={props?.object?.email}
                  classinput={"inputBg"}
                  placeholder={"Email address"}
                  type={"email"}
                  icon={"https://img.icons8.com/ios/50/filled-message.png"}
                  required={true}
                />
                <InputComponent
                  heading={"Password"}
                  // value={props?.object?.password}
                  classinput={"inputBg"}
                  placeholder={"Password"}
                  type={"password"}
                  icon={
                    "https://img.icons8.com/material-outlined/24/visible--v1.png"
                  }
                  // icon= {"https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/external-eye-devices-flatart-icons-outline-flatarticons.png"}
                  required={true}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    <Link className="account"> Don't have an account?</Link>
                  </p>
                  {/* {props.text !== "Register" && ( */}
                  <Link className="forget-password">Forget password?</Link>
                  {/* )} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonComponent
                    text={"Login"}
                    btnclass="btn-background mb-2"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="section-1">
          <div className="auth-slider-component-parent">
            <AuthSwiper />
          </div>
        </div>
      </div>
      <form
        className="form-main"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <InprogressComponent
          headerText={"Authenticating Your Account"}
          paraText={"Please wait while the page is loading."}
          loader={loader}
        />
      </form>
    </React.Fragment>
  );
}

export default AuthenticateComponent;
