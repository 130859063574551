import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import ButtonComponent from "../Components/buttonComponent";
import { useNavigate } from "react-router-dom";

const url =
  "https://findsocial.us11.list-manage.com/subscribe/post?u=7c37209c2ac784236aefbff0a&amp;id=bc9307256a&amp;f_id=0080b8e0f0";

const CustomForm = () => {
  const router = useNavigate();

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
    email = "";

    return (
      <div className="">
        <div className="Subscribe-section">
          <input
            ref={(node) => (email = node)}
            name="email"
            type="email"
            placeholder="Your email"
            className="m-0"
          />

          <ButtonComponent
            btnclass="btn-background mb-2 subscribe-btn"
            onClick={submit}
            text={"Subscribe"}
          ></ButtonComponent>
        </div>
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => {
              subscribe(formData);

              setTimeout(() => {
                router(0);
              }, 3000);
            }}
          />
        )}
      />
    </div>
  );
};

export default CustomForm;
