import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ButtonComponent from "./buttonComponent";
import AboutUsComponent from "../UI/aboutUsComponent";
import DrawerDialog from "./drawer";
import { Drawer } from "antd";

function LandingHeader({ userData }) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const [isShow, setIsShow] = React.useState(false);
  const handleClose2 = () => setIsShow(false);
  const handleopen = () => setIsShow(true);
  console.log(userData);
  return (
    <>
      <div className="landing-header">
        <div className="inner-landing-header">
          <Link className="inner-landing-header-image" to="/home">
            <img src="/Assets/Images/Logo/356x356.png" alt="" />
          </Link>
        </div>
        <div
          className="landing-header-hamburger-icon"
          onClick={() => handleopen()}
        >
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="0"
            viewBox="0 0 15 15"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.5 3C1.22386 3 1 3.22386 1 3.5C1 3.77614 1.22386 4 1.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3H1.5ZM1 7.5C1 7.22386 1.22386 7 1.5 7H13.5C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H1.5C1.22386 8 1 7.77614 1 7.5ZM1 11.5C1 11.2239 1.22386 11 1.5 11H13.5C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H1.5C1.22386 12 1 11.7761 1 11.5Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
        <div className="inner-landing-header-2">
          <Link
            className="landing-header-navbar-links"
            onClick={() => setModalOpen(true)}
          >
            About
          </Link>
          <Link className="landing-header-navbar-links" to={"/features"}>
            Features
          </Link>
          <Link className="landing-header-navbar-links" to={"/pricing"}>
            Pricing
          </Link>
          <Link className="landing-header-navbar-links" to={"/"}>
            Login
          </Link>
          <Link
            to={"/register"}
            style={{ textDecoration: "none" }}
            className=""
          >
            <ButtonComponent
              style={{
                whiteSpace: "nowrap",
              }}
              text={"Signup for free"}
              btnclass="btn-background res-landing-padding"
            />
          </Link>
        </div>
      </div>
      <Modal show={modalOpen} onHide={handleClose} className="modal-component">
        <AboutUsComponent />
      </Modal>
      <Drawer
        open={isShow}
        placement="right"
        title="Drawer"
        className="landing-header-drawer"
        onClose={handleClose2}
      >
        <HeaderSection setModalOpen={setModalOpen} />
      </Drawer>
    </>
  );
}
export default LandingHeader;

export function HeaderSection({ props }) {
  return (
    <div className="inner-landing-header-3">
      <Link
        className="landing-header-navbar-links"
        onClick={() => props?.setModalOpen(true)}
      >
        About
      </Link>
      <Link className="landing-header-navbar-links" to={"/features"}>
        Features
      </Link>
      <Link className="landing-header-navbar-links" to={"/pricing"}>
        Pricing
      </Link>
      <Link className="landing-header-navbar-links" to={"/"}>
        Login
      </Link>
      <Link to={"/register"} style={{ textDecoration: "none" }} className="">
        <ButtonComponent
          style={{
            whiteSpace: "nowrap",
          }}
          text={"Signup for free"}
          btnclass="btn-background res-landing-padding"
        />
      </Link>
    </div>
  );
}
