import React from "react";
import ImageComponent from "./imageComponent";
export default function ButtonComponent(props) {
  return (
    <button
      className={`btn-main ${props?.btnclass}`}
      style={props?.style}
      onClick={props.onClick}
      disabled={props?.disabled}
    >
      {props?.src && <ImageComponent src={props?.src} />}
      {props.text}
    </button>
  );
}
